import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
    Grid, Menu, Breadcrumb, Tab, Label, Icon
} from 'semantic-ui-react'
import ShowroomList from './ShowrromList';


function Showrrom() {
    let panes = [
        {
            menuItem: <Menu.Item key='TABpendente'>Showroom</Menu.Item>,
            render: () => <ShowroomList />
        },
    ]


    return (
        <>
            <Menu stackable size='mini'>
                <Menu.Item>
                    <Breadcrumb size='mini'>
                        <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron' />
                        <Breadcrumb.Section active>Showroom</Breadcrumb.Section>
                    </Breadcrumb>
                </Menu.Item>
            </Menu>
            <div>
                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        </>
    );
}


const mapStateToProps = (state) => ({
    // assistencias: state.AssistenciaReducer.assistencia
})


export default withRouter(connect(mapStateToProps, {
}
)(Showrrom));