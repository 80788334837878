import { useState } from 'react';
import { Modal, ModalContent, Dropdown, Input, Button, ModalHeader } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { showroomCriaLocalizacaoAction, showroomListAction, showroomAuditoriaAction } from '../../actions/ShowroomActions';



function ModalAddExpositor({ open, onClose, isShowroomCriaLocalizacaoLoading, showroomCriaLocalizacaoAction, showroomListAction, showroomAuditoriaAction, cdatual }) {

    const [cd, setCD] = useState("");
    const [localizacao, setLocalizacao] = useState("");
    const [errors, setErrors] = useState({ sku: false, cd: false });

    const optionscd = [
        { key: 'euclides', text: 'Euclides', value: 1 },
        { key: 'boavista', text: 'Boa Vista', value: 2 }
    ];

    const handleSave = () => {
        const newErrors = { localizacao: false, cd: false };

        if (!localizacao) newErrors.localizacao = true;
        if (!cd) newErrors.cd = true;

        setErrors(newErrors);

        // Verifica se existe algum erro, se sim, não faz nada
        if (Object.values(newErrors).includes(true)) {
            return;
        }
        const data = {
            localizacao: localizacao,
            cd: cd,
            // userid: userid
        }
        showroomCriaLocalizacaoAction(data).then((dados) => {
            showroomListAction(cdatual)
            showroomAuditoriaAction()
            handleModalClose()
            onClose();
        })
        console.log(data)

    };

    const handleModalClose = () => {
        // Limpa os campos ao fechar o modal
        setCD("");
        setLocalizacao("");
        setErrors({ cd: false, localizacao: false });
    };


    return (
        <>
            <Modal open={open} closeIcon onClose={() => { handleModalClose(); onClose(); }} ocloseOnDimmerClick={false} >
                <ModalContent>
                    <ModalHeader style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px' }}>Adicionar Expositor</ModalHeader>
                    <div style={{ marginBottom: '15px', display: 'flex', gap: '15px' }}>
                        <div style={{ width: '30%' }}>
                            <label style={{ display: 'block', marginBottom: '5px' }}>Centro de distribuição</label>
                            <Dropdown
                                options={optionscd}
                                selection
                                placeholder='Selecione uma CD'
                                name='cd'
                                fluid
                                value={cd || ""}
                                onChange={(e, { value }) => setCD(value)}
                                search
                                style={{ width: '100%' }}
                                error={errors.cd}
                            />
                        </div>
                        <div style={{ width: '30%' }}>
                            <label style={{ display: 'block', marginBottom: '5px' }}>Nome da Localização</label>
                            <Input
                                onChange={(e, { value }) => setLocalizacao(value)}
                                name='nomelocalizacao'
                                value={localizacao || ''}
                                style={{ width: '100%' }}
                                error={errors.localizacao}
                            />
                        </div>
                        <div style={{ width: '30%', marginTop: '25px' }}>
                            <Button
                                color="blue"
                                onClick={handleSave}
                                // style={{ marginLeft: '20px' }}
                                loading={isShowroomCriaLocalizacaoLoading}
                                disabled={isShowroomCriaLocalizacaoLoading}
                            >
                                Salvar
                            </Button>
                        </div>


                    </div>
                </ModalContent>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => ({
    isShowroomCriaLocalizacaoLoading: state.ShowroomReducer.isShowroomCriaLocalizacaoLoading,
    cdatual: state.ShowroomReducer.cdatual
});

export default withRouter(connect(mapStateToProps, {
    showroomCriaLocalizacaoAction,
    showroomListAction,
    showroomAuditoriaAction
})(ModalAddExpositor));