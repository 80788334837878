import { useState, useMemo } from 'react';
import { Modal, ModalContent, Input, Button, Dropdown, ModalHeader } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { showroomInfoSKUAction, showroomSaveNewProdAction, showroomListAction, showroomCDAction, showroomAuditoriaAction } from '../../actions/ShowroomActions';
import { NumericFormat } from 'react-number-format';

function ModalAddProduto({ open, onClose, showroomInfoSKUAction, showroomlist, showroominfosku, showroomatributos, showroomAuditoriaAction, isShowroomInfoLoading, showroomSaveNewProdAction, userid, isShowroomSaveLoading, showroomListAction, showroomCDAction, showroomalocalizacao, isShowroomLocalizacaoLoading, cdatual }) {
    const [sku, setSku] = useState("");
    const [nomeprod, setNomeProd] = useState("");
    const [valor, setValor] = useState("");
    const [localizacao, setLocalizacao] = useState("");
    const [cd, setCD] = useState("");
    const [atributos, setAtributos] = useState([{ atributo: "", cor: "" }]);
    const [atributos2, setAtributos2] = useState([{ atributo: "", cor: "" }]);
    const [errors, setErrors] = useState({ sku: false, nomeprod: false, valor: false, localizacao: false });
    const [atributosSalvos, setAtributosSalvos] = useState([]);
    const [atributosSalvos2, setAtributosSalvos2] = useState([]);
    const [prodDuplicado, setProdDuplicado] = useState("");


    const buscarInfoSKU = () => {
        showroomInfoSKUAction(sku).then((item) => {
            setNomeProd(item.nome);
            setValor(item.preco);
        });
    };

    const buscarInfoCD = () => {
        console.log(cd)
        showroomCDAction(cd)
        // showroomInfoSKUAction(cd).then((item) => {
        //     setNomeProd(item);
        // });
    };



    const localizacaoOptions = useMemo(() => {
        const uniqueLocalizacoes = Array.from(new Set(showroomalocalizacao.map(item => item.localizacao)));
        return uniqueLocalizacoes.map(loc => ({ key: loc, text: loc, value: loc }));
    }, [showroomalocalizacao]);

    const atributoOptions = useMemo(() => {
        const uniqueatributos = Array.from(new Set(showroomatributos.map(item => item.descrcar)));
        return uniqueatributos.map(loc => ({ key: loc, text: loc, value: loc }));
    }, [showroomatributos]);

    const optionscd = [
        { key: 'euclides', text: 'Euclides', value: 1 },
        { key: 'boavista', text: 'Boa Vista', value: 2 }
    ];


    const handleAtributoChange = (index, value) => {
        const atributoSelecionado = showroomatributos.find(item => item.descrcar === value);
        const novosAtributos = [...atributos];

        // Verifica se já existe um atributo igual selecionado e impede a duplicação
        if (novosAtributos.some((attr, i) => attr.atributo === value && i !== index)) {
            return;
        }

        novosAtributos[index].atributo = value;
        novosAtributos[index].cor = atributoSelecionado ? atributoSelecionado.ValorAtributo : "";

        const novoAtributoSalvo = atributoSelecionado
            ? { codigo: atributoSelecionado.codcar, nome: atributoSelecionado.ValorAtributo }
            : null;

        const novosAtributosSalvos = [...atributosSalvos];
        novosAtributosSalvos[index] = novoAtributoSalvo;

        setAtributosSalvos(novosAtributosSalvos);
        setAtributos(novosAtributos);
    };


    const handleAtributoChange2 = (index, value) => {
        const atributoSelecionado = showroomatributos.find(item => item.descrcar === value);
        const novosAtributos = [...atributos2];

        // Verifica se já existe um atributo igual selecionado e impede a duplicação
        if (novosAtributos.some((attr, i) => attr.atributo === value && i !== index)) {
            return;
        }

        novosAtributos[index].atributo = value;
        novosAtributos[index].cor = atributoSelecionado ? atributoSelecionado.ValorAtributo : "";

        const novoAtributoSalvo = atributoSelecionado
            ? { codigo: atributoSelecionado.codcar, nome: atributoSelecionado.ValorAtributo }
            : null;

        const novosAtributosSalvos = [...atributosSalvos2];
        novosAtributosSalvos[index] = novoAtributoSalvo;

        setAtributosSalvos2(novosAtributosSalvos);
        setAtributos2(novosAtributos);
    };

    const handleCorChange = (index, value) => {
        const novosAtributos = [...atributos];
        novosAtributos[index].cor = value;
        setAtributos(novosAtributos);
    };

    const handleCorChange2 = (index, value) => {
        const novosAtributos = [...atributos];
        novosAtributos[index].cor = value;
        setAtributos(novosAtributos);
    };

    const handleRemoveAtributo = (index) => {
        setAtributos([{ atributo: "", cor: "" }]);
        setAtributosSalvos([])
    };

    const handleRemoveAtributo2 = (index) => {
        setAtributos2([{ atributo: "", cor: "" }]);
        setAtributosSalvos2([])
    };

    const handleSave = () => {
        const newErrors = { sku: false, nomeprod: false, valor: false, localizacao: false, cd: false };

        if (!sku) newErrors.sku = true;
        if (!nomeprod) newErrors.nomeprod = true;
        if (!valor) newErrors.valor = true;
        if (!localizacao) newErrors.localizacao = true;
        if (!cd) newErrors.cd = true;

        setErrors(newErrors);

        // Se houver erro, interrompe a função
        if (Object.values(newErrors).includes(true)) {
            return;
        }

        const localizacaoID = showroominfosku.find(item => item.localizacao === localizacao)?.ID;

        // console.log(showroominfosku)
        const produtoExistente = showroomlist.find(item => {
            // console.log("Produto no banco:", item.codprod, "| Localização no banco:", item.idsr);
            // console.log("Produto inserido:", sku, "| Localização inserida:", localizacaoID);
            return item.codprod == sku && item.idsr == localizacaoID;
        });


        if (produtoExistente) {
            setProdDuplicado("Erro: Este produto já está cadastrado nesta localização.");
            return;
        }



        const data = {
            sku: sku,
            valor: valor,
            localizacao: localizacaoID,
            atributo1: atributosSalvos?.[0]?.nome,
            codatributo1: atributosSalvos?.[0]?.codigo,
            atributo2: atributosSalvos2?.[0]?.nome,
            codatributo2: atributosSalvos2?.[0]?.codigo,
            userid: userid
        };

        showroomSaveNewProdAction(data).then(() => {
            setProdDuplicado("")
            handleModalClose();
            onClose();
            showroomListAction(cdatual);
            showroomAuditoriaAction()
        });
    };


    const handleModalClose = () => {
        // Limpa os campos ao fechar o modal
        setSku("");
        setNomeProd("");
        setValor("");
        setLocalizacao("");
        setAtributos([{ atributo: "", cor: "" }]);
        setAtributos2([{ atributo: "", cor: "" }]);
        setErrors({ sku: false, nomeprod: false, valor: false, localizacao: false });
    };


    const atributoOptionsFiltradas = useMemo(() => {
        return atributos.map((item, index) => {
            const selecionados = atributos.map(attr => attr.atributo).filter(attr => attr);

            return atributoOptions.filter(option =>
                !selecionados.includes(option.value) || option.value === item.atributo
            );
        });
    }, [atributos, atributoOptions]);

    return (
        <Modal open={open} closeIcon onClose={() => { handleModalClose(); onClose(); }} closeOnDimmerClick={false}>
            <ModalContent>
                <ModalHeader style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px' }}>Adicionar Produto</ModalHeader>
                <div style={{ marginBottom: '15px', display: 'flex', gap: '15px' }}>
                    <div style={{ width: '30%' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>SKU</label>
                        <Input
                            placeholder='Ex: 5489'
                            onChange={(e, { value }) => setSku(value)}
                            loading={isShowroomInfoLoading}
                            name='skuprod'
                            maxLength={5}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onBlur={buscarInfoSKU}
                            style={{ width: '100%' }}
                            error={errors.sku}
                        />
                    </div>
                    <div style={{ width: '61%' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>Nome do produto</label>
                        <Input
                            onChange={(e, { value }) => setNomeProd(value)}
                            name='nomeprod'
                            value={nomeprod || ''}
                            style={{ width: '100%' }}
                            error={errors.nomeprod}
                        />
                    </div>
                </div>
                <div style={{ marginBottom: '15px', display: 'flex', gap: '10px' }}>
                    <div style={{ width: '30%' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>Centro de distribuição</label>
                        <Dropdown
                            options={optionscd}
                            selection
                            placeholder='Selecione uma CD'
                            name='cd'
                            fluid
                            value={cd || ""}
                            onChange={(e, { value }) => setCD(value)}
                            search
                            style={{ width: '100%' }}
                            error={errors.cd}
                            loading={isShowroomLocalizacaoLoading}
                            onBlur={buscarInfoCD}
                        />
                    </div>
                    <div style={{ width: '30%' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>Localização</label>
                        <Dropdown
                            options={localizacaoOptions}
                            selection
                            placeholder='Selecione uma localização'
                            name='localizacao'
                            fluid
                            value={localizacao || ""}
                            onChange={(e, { value }) => setLocalizacao(value)}
                            search
                            style={{ width: '100%' }}
                            error={errors.localizacao}

                        />
                    </div>
                    <div style={{ width: '30%' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>Valor</label>
                        <NumericFormat
                            prefix="R$ "
                            decimalSeparator=","
                            thousandSeparator="."
                            decimalScale={2}
                            fixedDecimalScale
                            allowNegative={false}
                            placeholder="R$ 0,00"
                            customInput={Input}
                            value={valor || ''}
                            onValueChange={(values) => setValor(values.value)}
                            style={{ width: '100%' }}
                            error={errors.valor}
                            disabled={true}
                        />
                    </div>
                </div>
                {atributos.map((item, index) => {
                    const atributosDisponiveis = atributoOptions.filter(option =>
                        !atributos.some(attr => attr.atributo === option.value) || item.atributo === option.value
                    );

                    return (
                        <>
                            {atributos.slice(0, 2).map((item, index) => (
                                <>
                                    <div key={index} style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}>
                                        <div style={{ width: '30%' }}>
                                            <label style={{ display: 'block', marginBottom: '5px' }}>Atributo Nome</label>
                                            <Dropdown
                                                options={atributoOptionsFiltradas[index]}
                                                selection
                                                placeholder='Selecione um atributo'
                                                value={item.atributo || ""}
                                                onChange={(e, { value }) => handleAtributoChange(index, value)}
                                                fluid
                                                search
                                            />
                                        </div>
                                        <div style={{ width: '30%' }}>
                                            <label style={{ display: 'block', marginBottom: '5px' }}>Valor do Atributo</label>
                                            <Input
                                                value={item.cor}
                                                onChange={(e, { value }) => handleCorChange(index, value)}
                                                placeholder="Valor do atributo"
                                                style={{ width: '100%' }}
                                                disabled
                                            />
                                        </div>
                                        <Button
                                            icon="trash"
                                            color="red"
                                            onClick={() => handleRemoveAtributo(index)}
                                            style={{ marginTop: '23px' }}
                                        />
                                    </div>
                                </>
                            ))}
                            {atributos2.slice(0, 2).map((item, index) => (
                                <div key={index} style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}>
                                    <div style={{ width: '30%' }}>
                                        <label style={{ display: 'block', marginBottom: '5px' }}>Atributo Coluna</label>
                                        <Dropdown
                                            options={atributoOptionsFiltradas[index]}
                                            selection
                                            placeholder='Selecione um atributo'
                                            value={item.atributo || ""}
                                            onChange={(e, { value }) => handleAtributoChange2(index, value)}
                                            fluid
                                            search
                                        />
                                    </div>
                                    <div style={{ width: '30%' }}>
                                        <label style={{ display: 'block', marginBottom: '5px' }}>Valor do Atributo</label>
                                        <Input
                                            value={item.cor}
                                            onChange={(e, { value }) => handleCorChange2(index, value)}
                                            placeholder="Valor do atributo"
                                            style={{ width: '100%' }}
                                            disabled
                                        />
                                    </div>
                                    <Button
                                        icon="trash"
                                        color="red"
                                        onClick={() => handleRemoveAtributo2(index)}
                                        style={{ marginTop: '23px' }}
                                    />
                                </div>
                            ))}
                        </>


                    );
                })}
                {prodDuplicado ? (
                    <div style={{ color: 'red' }}>{prodDuplicado}</div>
                )
                    : ("")}
                <Button
                    color="blue"
                    onClick={handleSave}
                    style={{ marginTop: '20px' }}
                    loading={isShowroomSaveLoading}
                    disabled={isShowroomSaveLoading}
                >
                    Salvar
                </Button>
            </ModalContent>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    showroominfosku: state.ShowroomReducer.showroominfosku,
    showroomatributos: state.ShowroomReducer.showroomatributos,
    isShowroomInfoLoading: state.ShowroomReducer.isShowroomInfoLoading,
    userid: state.LoginReducer.userid,
    isShowroomSaveLoading: state.ShowroomReducer.isShowroomSaveLoading,
    showroomlist: state.ShowroomReducer.showroomlist,
    showroomalocalizacao: state.ShowroomReducer.showroomalocalizacao,
    isShowroomLocalizacaoLoading: state.ShowroomReducer.isShowroomLocalizacaoLoading,

    cdatual: state.ShowroomReducer.cdatual
});

export default withRouter(connect(mapStateToProps, {
    showroomInfoSKUAction,
    showroomSaveNewProdAction,
    showroomListAction,
    showroomCDAction,
    showroomAuditoriaAction
})(ModalAddProduto));
