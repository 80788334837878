import React, { Component, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Segment, Tab, Dropdown, Statistic, Message, Dimmer, Loader, Image, Popup } from 'semantic-ui-react';
import moment from 'moment';
import If from '../../utils/If';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Buffer } from 'buffer';


import styled from 'styled-components';



import { useDropzone } from 'react-dropzone';

//const Excel = require('exceljs');
//const ExcelJS = require('exceljs');
//import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import Excel from "exceljs";





const inlineStyle = {
    modal: {
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
};

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;




const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};




function StyledDropzone({ props,
    concimktplaceid
    , conciListMktPlace
    , conciarquivojson
    , marketplacesConciliacaoHandleChangeAction
    , marketplacesConciliacaoImportAction
    , marketplacesConciliacaoImportArquivoAction
    , marketplacesConciliacaoListImportacoesAction
    , marketplacesConciliacaoDeleteAction
}) {


    const handleDropMktPlace = (e, { value }) => {
        // console.log(value)
        marketplacesConciliacaoHandleChangeAction({ target: { 'name': 'concimktplaceid', 'value': value.toString() } })

    }


    function tratapedidoleroy(campo) {
        let valor = 0;

        try {
            var str = campo;
            str = str.replace(/[^0-9]/g, "");
            valor = Number.parseInt(str);

        } catch (error) {

        }

        return valor;


    }

    const onDrop = useCallback(acceptedFiles => {
        // console.log('passou aqui')

        if (acceptedFiles.length > 0) {

            const file = acceptedFiles[0];

            const reader = new FileReader();

            reader.readAsArrayBuffer(file)
            reader.onload = () => {



                //xlsx----------------------------------------------
                const buffer = reader.result;
                const wb = new Excel.Workbook();

                let dados = [];
                let maadeiramadeira = "";
                wb.xlsx.load(buffer).then(workbook => {
                    //console.log(workbook, 'workbook instance')
                    workbook.eachSheet((sheet, id) => {
                        //console.log('linhas:' + sheet.length);
                        sheet.eachRow((row, rowIndex) => {
                            //cabeçalho/colunas do excel
                            if (rowIndex == 1) {
                                // console.log('colunas:' + row.values.length)
                                maadeiramadeira = row.values[5]
                            } else {

                                const magalu = row.values[24]
                                //leroy merlin layout
                                if (magalu != "Magalu" && maadeiramadeira != "Pedido MM") {
                                    dados.push({
                                        pedido: (row.values[7] == null ? tratapedidoleroy(row.values[5]) : row.values[7]) //NÃºmero da transaÃ§Ã£o
                                        , tipo: row.values[12]//Tipo 
                                        , parc_atual: '0'
                                        , parc_total: '0'
                                        //, debito: (row.values[15] != null  ? parseFloat(Number(row.values[15]).toFixed(2)) : 0)//DÃ©bito
                                        //, credito: (row.values[16] != null  ? parseFloat(Number(row.values[16]).toFixed(2)) : 0)//CrÃ©dito
                                        , valor: ((row.values[15] != null ? parseFloat(Number(row.values[15]).toFixed(2)) : 0) == 0 ? (row.values[16] != null ? parseFloat(Number(row.values[16]).toFixed(2)) : 0) : (row.values[15] != null ? parseFloat(Number(row.values[15]).toFixed(2)) : 0))
                                        , taxa: 0

                                    })
                                }
                                //Madeira Madeira layout
                                else if (maadeiramadeira === 'Pedido MM') {

                                    // console.log(row.values)
                                    let numeros = "";
                                    let valorNumerico = 0;
                                    let ultimoCampo = row.values[8] === undefined ? '0' : row.values[8];// Último elemento do array

                                    if (typeof ultimoCampo === 'string') {
                                        // console.log(ultimoCampo)
                                        numeros = ultimoCampo.match(/\d+/g); // Extrai todos os números do último campo
                                        valorNumerico = parseFloat(row.values[2].replace(/[^\d,-]/g, '').replace(',', '.'));
                                        // Seu código continua aqui...
                                    } else {
                                        // if (ultimoCampo == undefined) {
                                        //     numeros = ultimoCampo.match('1 / 1');
                                        // }
                                        valorNumerico = parseFloat(row.values[2].replace(/[^\d,-]/g, '').replace(',', '.'));
                                        // console.log(valorNumerico)
                                        // Tratar o caso em que ultimoCampo não é uma string
                                        // console.log('ERRO:', ultimoCampo)
                                        // console.error("O último campo não é uma string.");
                                    }
                                    // console.log(numeros)

                                    // console.log(numeros, "MM")
                                    if (numeros == '0' || numeros == 0) {
                                        // console.log('TARIFA', row.values[4])
                                        dados.push({
                                            pedido: Number(row.values[4]) ? Number(row.values[4]) : 0
                                            , tipo: row.values[3]
                                            , parc_atual: '1'
                                            , parc_total: '1'
                                            , valor: valorNumerico
                                            , taxa: 0
                                        })
                                    } else {
                                        // console.log('OUTROS VALORES', row.values[4])
                                        dados.push({
                                            pedido: Number(row.values[4]) ? Number(row.values[4]) : 0
                                            , tipo: row.values[3]
                                            , parc_atual: numeros[0]
                                            , parc_total: numeros[1]
                                            , valor: valorNumerico
                                            , taxa: 0
                                        })
                                    }
                                    // console.log(dados)

                                }
                                //Magalu layout
                                else {

                                    const taxa1 = parseFloat(Number(row.values[15]))
                                    const taxa2 = parseFloat(Number(row.values[30]))

                                    dados.push({
                                        pedido: (row.values[7] == null ? tratapedidoleroy(row.values[4]) : row.values[7]) //NÃºmero da transaÃ§Ã£o
                                        , tipo: row.values[9]//Tipo de pagamento
                                        , parc_atual: row.values[10]
                                        , parc_total: row.values[11]
                                        , valor: ((row.values[12] != null ? parseFloat(Number(row.values[12]).toFixed(2)) : 0))
                                        , taxa: (row.values[30] != undefined ? taxa1 + taxa2 : 0)
                                        , taxa_ted: row.values[27]
                                    })

                                }
                            }
                        })
                    })
                })


                //fs.readFileSync(entidade.conciarquivobuffer)


                //console.log('file:');
                //console.log(file);

                // console.log(dados)
                marketplacesConciliacaoImportArquivoAction(dados, buffer, file.name)
                //---------------------------------------------------
            }

        }





    }, []);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        //fileRejectionItems
    } = useDropzone({
        onDrop
        , maxFiles: 1
        //, accept: '.xlsx, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        , accept: '.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const removeAll = () => {

        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
        //inputRef.current.value = ''

    }


    function refreshPage() {
        window.location.reload();
    }


    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));







    return (
        <div className="container">
            <div>
                <div style={{ textAlign: "right" }}>
                    <Popup
                        trigger={
                            <p style={{ display: "inline-block", marginRight: "20px", fontWeight: "bold", marginLeft: "10px" }}>Template Leroy</p>
                        }
                        flowing
                        hoverable
                        pinned
                        on={["hover", "click"]}>
                        Número da transação, Coluna <b>"G"</b><br />
                        Descrição, Coluna <b>"K"</b><br />
                        Tipo, Coluna <b>"L"</b><br />
                        Valor, Coluna<b>"N"</b><br />

                    </Popup>
                    <Popup
                        trigger={
                            <p style={{ display: "inline-block", marginRight: "20px", fontWeight: "bold", marginLeft: "10px" }}>Template Magazine Luiza</p>
                        }
                        flowing
                        hoverable
                        pinned
                        on={["hover", "click"]}>
                        Número da nota fiscal, Coluna <b>"G"</b><br />
                        Método de pagamento, Coluna <b>"I"</b><br />
                        Parcela atual, Coluna <b>"J"</b><br />
                        Total de parcelas, Coluna <b>"K"</b><br />
                        Valor fixo por pedido parcelada, Coluna <b>"AD"</b><br />
                        Taxa de transferência, Coluna <b>"AA"</b><br />
                    </Popup>
                    <Popup
                        trigger={
                            <p style={{ display: "inline-block", fontWeight: "bold", marginLeft: "10px" }}>Template MadeiraMadeira</p>
                        }
                        flowing
                        hoverable
                        pinned
                        on={["hover", "click"]}>
                        Pedido , Coluna <b>"D"</b><br />
                        Descrição, Coluna <b>"C"</b><br />
                        Valor , Coluna <b>"B"</b><br />
                        Parcela, Coluna <b>"H"</b><br />
                    </Popup>
                </div>
            </div>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop xlsx, ou clique para selecionar o arquivo do Marketplace</p>
            </div>
            <aside>
                <h4>Files </h4>
                <ul>{files}</ul>
            </aside>

            <If test={files.length > 0}>
                <Dropdown
                    options={conciListMktPlace}

                    selection
                    placeholder='MarketPlace'
                    name='concimktplaceid'
                    fluid
                    defaultValue={concimktplaceid.toString()}
                    onChange={handleDropMktPlace}


                />

            </If>


        </div>
    );
}

class Conciliacao extends Component {


    state = {
        isSaving: false, openEntrega: false, isSavingNSaiu: false
        , status: '', mensagem: ''
        , isloadingimportacoes: false
        , isloadingimport: false
    }

    constructor(props) {
        super(props);

    }



    componentDidMount() {

        this.setState({ isloadingimportacoes: true });
        this.props.marketplacesConciliacaoListImportacoesAction().then(d => {
            this.setState({ isloadingimportacoes: false });
        })
    }




    //download pdf from stream blob
    onDownloadXlsx = (arquivonome, arquivo) => {

        let buffer = Buffer.from(arquivo.data);
        let arraybuffer = Uint8Array.from(buffer).buffer;

        const file = new Blob(
            [arraybuffer],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        //const fileURL = URL.createObjectURL(file);
        //window.open(fileURL);
        saveAs(file, `${arquivonome}.xlsx`);

    }


    onChangeDTDeposito = (item, data) => {
        this.props.marketplacesConciliacaoImportHandleChangeAction(item.nurepassemkp, 'dtdeposito', data);
        //console.log("dataaaaaa"+item.nurepassemkp)
        //console.log(data)
    }



    render() {
        let { loader

            , concimktplaceid, conciListMktPlace

            , conciimportacoes
            , conciimportacoesloader
            , conciisimporting
            , conciarquivojson
            , conciarquivobuffer
            , concirepasse, concirepasselog

            , marketplacesConciliacaoHandleChangeAction
            , marketplacesConciliacaoImportAction
            , marketplacesConciliacaoImportArquivoAction
            , marketplacesConciliacaoListImportacoesAction
            , marketplacesConciliacaoProcessImportacoesAction
            , marketplacesConciliacaoListRepasseAction
            , marketplacesConciliacaoDeleteAction

        } = this.props;


        const getstatus = (acao) => {
            if (acao == 0) {
                return "Aguardando Processar"
            } else if (acao == 1) {
                return "Processado"
            } else if (acao == 2) {
                return "Baixado"
            } else {
                return "-----"

            }


        }


        let identificador = -999;
        let mostralinha = false;

        return (
            <Tab.Pane loading={false}>
                <Segment>
                    <StyledDropzone
                        concimktplaceid={concimktplaceid}
                        conciListMktPlace={conciListMktPlace}
                        conciarquivojson={conciarquivojson}
                        marketplacesConciliacaoHandleChangeAction={marketplacesConciliacaoHandleChangeAction}
                        marketplacesConciliacaoImportAction={marketplacesConciliacaoImportAction}
                        marketplacesConciliacaoImportArquivoAction={marketplacesConciliacaoImportArquivoAction}
                        marketplacesConciliacaoListImportacoesAction={marketplacesConciliacaoListImportacoesAction}

                    />
                    <If test={concimktplaceid != '0' && conciarquivojson.length > 0}>
                        <Button content='Importar' primary onClick={() => {

                            marketplacesConciliacaoImportAction().then(d => {
                                this.setState({ mensagem: d[0].mensagem, status: d[0].status })
                            }).catch(err => {
                                console.log(err)
                            })

                        }} />
                    </If>

                    <If test={this.state.mensagem != ''}>
                        <Message attached='top' color={(this.state.status === 'OK' ? 'olive' : 'red')}>
                            {this.state.mensagem}
                        </Message>
                    </If>

                </Segment>
                <br></br><br></br>

                <If test={this.state.isloadingimportacoes}>
                    <Segment>
                        <Dimmer active inverted>
                            <Loader size='large'>Carregando importações</Loader>
                        </Dimmer>

                        <Image src='/img/paragraph.png' />
                    </Segment>
                </If>

                <If test={conciimportacoes.length > 0 && !this.state.isloadingimportacoes}>

                    <StyledTable celled id={'12121'} compact='very' size='small'>
                        <StyledTableHeader>
                            <Table.Row>
                                <Table.HeaderCell width="1">ID</Table.HeaderCell>
                                <Table.HeaderCell width="2">Arquivo</Table.HeaderCell>
                                <Table.HeaderCell width="2">MarketPlace</Table.HeaderCell>
                                <Table.HeaderCell width="2">Dt Importaçao</Table.HeaderCell>
                                <Table.HeaderCell width="2">Usuário</Table.HeaderCell>
                                <Table.HeaderCell width="1">Status</Table.HeaderCell>
                                <Table.HeaderCell width="2">Ações</Table.HeaderCell>

                                <Table.HeaderCell width="3">Depósito</Table.HeaderCell>
                                {
                                    /*
                                    <Table.HeaderCell width="1">Excluir</Table.HeaderCell>
                                    */
                                }


                            </Table.Row>
                        </StyledTableHeader>

                        <StyledTableBody>
                            {

                                conciimportacoes.map(item => (

                                    <Table.Row key={`id${item.nurepassemkp}`}>
                                        <Table.Cell>{item.nurepassemkp}</Table.Cell>
                                        <Table.Cell>{item.arquivonome}</Table.Cell>
                                        <Table.Cell>{item.mktplace}</Table.Cell>
                                        <Table.Cell>{moment(item.dtimportacao).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                                        <Table.Cell>{item.login}</Table.Cell>
                                        <Table.Cell>{getstatus(item.status)}</Table.Cell>

                                        <Table.Cell>
                                            <If test={item.status == 0}>

                                                <Popup
                                                    trigger={<Button icon='cog' color={'grey'} size="mini"
                                                        loading={item.isprocessing}
                                                        onClick={(e) => (item.isprocessing == false ? marketplacesConciliacaoProcessImportacoesAction(item.nurepassemkp) : null)}

                                                    />}
                                                    content={`Realiza processamento do Arquivo importado para buscar informações no ERP`} wide='very'
                                                />


                                            </If>
                                            <If test={item.status == 1 || item.status == 2}>


                                                <Popup
                                                    trigger={<Button icon='folder open' color={'blue'} size="mini"
                                                        loading={item.islistingrepasse}
                                                        onClick={(e) => (item.islistingrepasse == false ? marketplacesConciliacaoListRepasseAction(item.nurepassemkp) : null)}

                                                    />}
                                                    content={`Exibe detalhes do processamento`} wide='very'
                                                />



                                            </If>


                                            <Popup
                                                trigger={<Button icon='file excel outline ' color={'green'} size="mini"
                                                    loading={false}
                                                    onClick={(e) => this.onDownloadXlsx(item.arquivonome, item.arquivo)}

                                                />}
                                                content={`Faz download do arquivo importado`} wide='very'
                                            />



                                            <If test={item.status == 0 || item.status == 1}>
                                                <Popup
                                                    trigger={<Button icon='remove ' color={'red'} size="mini"
                                                        loading={item.isloadingdelete}
                                                        onClick={(e) => marketplacesConciliacaoDeleteAction(item.nurepassemkp)}
                                                    //onClick={(e) => this.onChangeDevolucao(e, pedido, carga)}
                                                    />}
                                                    content={`Remover o arquivo e todos itens processados nele.`} wide='very'
                                                />




                                            </If>

                                        </Table.Cell>







                                        <Table.Cell>

                                            <If test={item.status == 2}>
                                                {moment(item.dtdeposito).format("DD/MM/YYYY HH:mm")}
                                            </If>

                                            <If test={item.status == 1}>
                                                <DateTimePicker
                                                    onChange={(e) => { this.onChangeDTDeposito(item, e) }}

                                                    value={
                                                        new Date(item.dtdeposito)

                                                    }
                                                    locale="pt-br"
                                                    required={true}
                                                    clearIcon=""
                                                    disableClock={true}
                                                    isClockOpen={false}

                                                />


                                                <Popup
                                                    trigger={
                                                        <Button key={`save_${item.nurepassemkp}`}
                                                            icon={'gavel'} color={'orange'} size="mini"
                                                            onClick={(e) => {
                                                                if (!item.isprocessandobaixa) {
                                                                    this.props.marketplacesConciliacaoRepasseAction(item.nurepassemkp, item.dtdeposito).then(d => {
                                                                        //alert('retornou no componente');

                                                                        this.setState({ mensagem: d[0].mensagem, status: d[0].status })
                                                                    })
                                                                }
                                                            }}
                                                            loading={item.isprocessandobaixa}
                                                        />

                                                    }
                                                    content={`Realizar a baixa de acordo com a data de depósito ${moment(item.dtdeposito).format("DD/MM/YYYY HH:mm")}. Esse processo pode demorar em até 10 minutos para ser executado e não pode sair dessa tela até receber uma confirmação de processameto.`} wide='very'
                                                />



                                            </If>



                                        </Table.Cell>



                                    </Table.Row>

                                ))

                            }


                        </StyledTableBody>
                    </StyledTable>

                </If>






                <br></br><br></br>

                <If test={concirepasse.length > 0}>
                    <Statistic.Group>
                        <Statistic>
                            <Statistic.Value>{concirepasse[0]?.nurepassemkp}</Statistic.Value>
                            <Statistic.Label>NUREPASSE</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>{concirepasse.length}</Statistic.Value>
                            <Statistic.Label>Linhas</Statistic.Label>
                        </Statistic>
                        <Statistic color={(concirepasselog.length > 0 ? 'red' : 'green')}>
                            <Statistic.Value>{concirepasselog.length}</Statistic.Value>
                            <Statistic.Label>Problemas</Statistic.Label>
                        </Statistic>
                    </Statistic.Group>

                </If>

                <If test={concirepasselog.length > 0}>

                    <StyledTable celled id={'concirepasselog'} color='red' inverted compact='very' size='small' loading={true}>
                        <StyledTableHeader>
                            <Table.Row>
                                <Table.HeaderCell width="1">NUREPASSEMKP</Table.HeaderCell>
                                <Table.HeaderCell width="1">Parcela</Table.HeaderCell>
                                <Table.HeaderCell width="1">Sequencia</Table.HeaderCell>
                                <Table.HeaderCell width="2">Identificador</Table.HeaderCell>
                                <Table.HeaderCell width="4">Motivo</Table.HeaderCell>
                            </Table.Row>
                        </StyledTableHeader>

                        <StyledTableBody>
                            {

                                concirepasselog.map(item => (

                                    <Table.Row key={`rep${item.nurepassemkp} ${item.Identificador}`}>
                                        <Table.Cell>{item.nurepassemkp}</Table.Cell>
                                        <Table.Cell>{item.parcela}</Table.Cell>
                                        <Table.Cell>{item.sequencia}</Table.Cell>
                                        <Table.Cell>{item.identificador}</Table.Cell>
                                        <Table.Cell>{item.motivo}</Table.Cell>

                                    </Table.Row>

                                ))

                            }


                        </StyledTableBody>
                    </StyledTable>

                </If>


                <br></br><br></br>


                <If test={concirepasse.length > 0}>

                    <StyledTable celled id={'concirepasselog'} compact='very' size='small' loading={true}>
                        <StyledTableHeader>
                            <Table.Row>
                                <Table.HeaderCell width="1">NUREPASSEMKP</Table.HeaderCell>
                                <Table.HeaderCell width="1">Parcela</Table.HeaderCell>
                                <Table.HeaderCell width="1">Sequencia</Table.HeaderCell>
                                <Table.HeaderCell width="1">Identificador</Table.HeaderCell>
                                <Table.HeaderCell width="2">Descrição</Table.HeaderCell>
                                <Table.HeaderCell width="1">Valor</Table.HeaderCell>
                                <Table.HeaderCell width="1">Taxa</Table.HeaderCell>
                                <Table.HeaderCell width="1">CodParc</Table.HeaderCell>
                                <Table.HeaderCell width="1">NuNota</Table.HeaderCell>
                                <Table.HeaderCell width="1">NuFin</Table.HeaderCell>
                                <Table.HeaderCell width="1">VlrBaixa</Table.HeaderCell>
                                <Table.HeaderCell width="1">VlrMitra</Table.HeaderCell>
                                <Table.HeaderCell width="1">VlrLiquido</Table.HeaderCell>
                            </Table.Row>
                        </StyledTableHeader>

                        <StyledTableBody>
                            { //

                                concirepasse.map(item => {
                                    if (identificador == item.identificador) {
                                        mostralinha = false;


                                    } else {
                                        mostralinha = true;
                                        identificador = item.identificador;
                                    }


                                    return (



                                        <>

                                            <If test={mostralinha}>
                                                <Table.Row>
                                                    <Table.Cell colSpan='13'><hr></hr></Table.Cell>
                                                </Table.Row>
                                            </If>
                                            <Table.Row key={`rep${item.nurepassemkp} ${item.Identificador}`}>
                                                <Table.Cell>{item.nurepassemkp}</Table.Cell>
                                                <Table.Cell>{item.parcela}</Table.Cell>
                                                <Table.Cell>{item.sequencia}</Table.Cell>
                                                <Table.Cell>{item.identificador}</Table.Cell>
                                                <Table.Cell positive={(item.recdesp == 1 ? true : false)} negative={(item.recdesp == 1 ? false : true)}>{item.descricao}</Table.Cell>
                                                <Table.Cell positive={(item.recdesp == 1 ? true : false)} negative={(item.recdesp == 1 ? false : true)}>{(item.recdesp == 1 ? '+' : '-')}{item.valor.toFixed(2)}</Table.Cell>
                                                <Table.Cell negative={(item.taxa == null ? false : true)}>{(item.taxa == null ? '' : '-')}{item.taxa}</Table.Cell>
                                                <Table.Cell negative={(item.codparc == null ? true : false)}>{item.codparc}</Table.Cell>
                                                <Table.Cell negative={(item.nunota == null ? true : false)}>{item.nunota}</Table.Cell>
                                                <Table.Cell negative={(item.nufin == null ? true : false)}>{item.nufin}</Table.Cell>
                                                <Table.Cell negative={(item.vlrbaixa == null ? true : false)}>{item.vlrbaixa}</Table.Cell>
                                                <Table.Cell negative={(item.valormitra == null ? true : false)}>{(item.valormitra != null ? item.valormitra.toFixed(2) : '')}</Table.Cell>
                                                <Table.Cell>{(item.eventoid == 3 ? (item.valormitra - item.valor).toFixed(2) : '')}</Table.Cell>
                                            </Table.Row>




                                        </>

                                    )
                                })
                            }


                        </StyledTableBody>

                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan='12'>

                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </StyledTable>

                </If>





            </Tab.Pane>

        )
    }
}

Conciliacao.defaultProps = {
    loader: true
    , login: ''
    , concimktplaceid: ''
    , conciListMktPlace: []

    , conciimportacoes: []
    , conciimportacoesloader: false
    , conciisimporting: false
    , conciarquivojson: []
    , conciarquivobuffer: []
    , conciarquivonome: ''
    , concirepasse: []
    , concirepasselog: []

};

Conciliacao.propTypes = {

    loader: PropTypes.bool.isRequired
    , login: PropTypes.string.isRequired
    , concirepasse: PropTypes.array.isRequired
    , concirepasselog: PropTypes.array.isRequired

    , concimktplaceid: PropTypes.string.isRequired
    , conciListMktPlace: PropTypes.array.isRequired

    , marketplacesConciliacaoHandleChangeAction: PropTypes.func.isRequired
    , marketplacesConciliacaoListImportacoesAction: PropTypes.func.isRequired
    , marketplacesConciliacaoImportAction: PropTypes.func.isRequired
    , marketplacesConciliacaoImportArquivoAction: PropTypes.func.isRequired
    , marketplacesConciliacaoProcessImportacoesAction: PropTypes.func.isRequired
    , marketplacesConciliacaoListRepasseAction: PropTypes.func.isRequired
    , marketplacesConciliacaoImportHandleChangeAction: PropTypes.func.isRequired
    , marketplacesConciliacaoRepasseAction: PropTypes.func.isRequired
    , marketplacesConciliacaoDeleteAction: PropTypes.func.isRequired

};



export default Conciliacao
