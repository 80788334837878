import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./ShowroomAuditoria.css"; // Importando o CSS

function ShowroomAuditoria({ showroomauditoria }) {
    return (
        <div className="audit-container">
            <h2>Auditoria do Showroom</h2>
            <table className="audit-table">
                <thead>
                    <tr>
                        <th>Localização</th>
                        <th>CD</th>
                        <th>Quantidade</th>
                        <th>Versão</th>
                    </tr>
                </thead>
                <tbody>
                    {showroomauditoria.map((item, index) => (
                        <tr key={index}>
                            <td>{item.localizacao}</td>
                            <td>{item.cd}</td>
                            <td>{item.qntdade}</td>
                            <td>{item.versao}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

const mapStateToProps = (state) => ({
    showroomauditoria: state.ShowroomReducer.showroomauditoria,
});

export default withRouter(connect(mapStateToProps, {})(ShowroomAuditoria));
