import React from 'react';
import {
    Grid, Menu, Breadcrumb, Tab, Icon
} from 'semantic-ui-react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ListDenuncia from './ListDenuncia'
import ListOuvidoria from './ListOuvidoria';


function Ouvidoria(props) {

    const { userid } = props;


    let panes = [

    ];
    if (userid == 2 || userid == 3 || userid == 79 || userid == 86 | userid == 33) {
        panes.push(
            {
                menuItem: <Menu.Item key='TABpendente'><Icon name='balance scale' />Denúncia Anônima</Menu.Item>,
                render: () => <ListDenuncia />
            }
        )
    }
    panes.push(
        {
            menuItem: <Menu.Item key='TABpendente'><Icon name='bullhorn' />Ouvidoria Identificada</Menu.Item>,
            render: () => <ListOuvidoria />
        }
    )



    return (

        <>
            <Menu stackable size='mini'>
                <Menu.Item>
                    <Breadcrumb size='mini'>
                        <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron' />
                        <Breadcrumb.Section active>Ouvidoria</Breadcrumb.Section>
                    </Breadcrumb>
                </Menu.Item>
            </Menu>
            <div>
                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        </>
    );
}

const mapStateToProps = state => (
    {
        userid: state.LoginReducer.userid,
    }
);

export default withRouter(connect(mapStateToProps, {
}
)(Ouvidoria));