import { useState, useMemo } from 'react';
import { Modal, ModalContent, Dropdown, Button, ModalHeader } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { showroomDesativaLocalizacaoAction, showroomListAction, showroomCDAction, showroomAuditoriaAction } from '../../actions/ShowroomActions';



function ModalDesativaExpositor({ open, onClose, showroomCDAction, showroomDesativaLocalizacaoAction, showroomAuditoriaAction, showroomalocalizacao, isShowroomDesativaLocalizacaoLoading, showroomListAction, isShowroomLocalizacaoLoading, cdatual }) {

    const [sku, setSku] = useState("");
    const [localizacao, setLocalizacao] = useState("");
    const [cd, setCD] = useState("");
    const [errors, setErrors] = useState({ cd: false, localizacao: false });




    const optionscd = [
        { key: 'euclides', text: 'Euclides', value: 1 },
        { key: 'boavista', text: 'Boa Vista', value: 2 }
    ];

    const localizacaoOptions = useMemo(() => {
        return showroomalocalizacao.map(item => ({
            key: item.ID, // ID da localização
            text: item.localizacao, // Nome da localização
            value: item.ID // O dropdown vai armazenar o ID como valor
        }));
    }, [showroomalocalizacao]);


    const buscarInfoCD = () => {
        console.log(cd)
        showroomCDAction(cd)
        setLocalizacao("")
        // showroomInfoSKUAction(cd).then((item) => {
        //     setNomeProd(item);
        // });
    };
    const handleSave = () => {
        const newErrors = { localizacao: false, cd: false };

        if (!localizacao) newErrors.localizacao = true;
        if (!cd) newErrors.cd = true;

        setErrors(newErrors);

        // Verifica se existe algum erro, se sim, não faz nada
        if (Object.values(newErrors).includes(true)) {
            return;
        }
        const data = {
            localizacao: localizacao,
        }
        console.log(data)
        showroomDesativaLocalizacaoAction(data).then(() => {
            handleModalClose()
            onClose();
            showroomListAction(cdatual)
            showroomAuditoriaAction()
        })
    };


    const handleModalClose = () => {
        // Limpa os campos ao fechar o modal
        setLocalizacao("");
        setCD("");
    };


    return (
        <>
            <Modal open={open} closeIcon onClose={() => { handleModalClose(); onClose(); }} closeOnDimmerClick={false} >

                <ModalContent>
                    <ModalHeader style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px' }}>Desativar Expositor</ModalHeader>

                    <div style={{ marginBottom: '15px', display: 'flex', gap: '15px' }}>
                        <div style={{ width: '30%' }}>
                            <label style={{ display: 'block', marginBottom: '5px' }}>Centro de distribuição</label>
                            <Dropdown
                                options={optionscd}
                                selection
                                placeholder='Selecione uma CD'
                                name='cd'
                                fluid
                                value={cd || ""}
                                onChange={(e, { value }) => setCD(value)}
                                search
                                style={{ width: '100%' }}
                                error={errors.cd}
                                loading={isShowroomLocalizacaoLoading}
                                onBlur={buscarInfoCD}
                            />
                        </div>
                        <div style={{ width: '30%' }}>
                            <label style={{ display: 'block', marginBottom: '5px' }}>Localização</label>
                            <Dropdown
                                options={localizacaoOptions}
                                selection
                                placeholder='Selecione uma localização'
                                name='localizacao'
                                fluid
                                value={localizacao || ""}
                                onChange={(e, { value }) => setLocalizacao(value)}
                                search
                                style={{ width: '100%' }}
                                error={errors.localizacao}

                            />
                        </div>
                        <Button
                            color="blue"
                            onClick={handleSave}
                            style={{ marginTop: '25px' }}
                            loading={isShowroomDesativaLocalizacaoLoading}
                            disabled={isShowroomDesativaLocalizacaoLoading}
                        >
                            Salvar
                        </Button>
                    </div>
                </ModalContent>

            </Modal>
        </>
    );
}

const mapStateToProps = (state) => ({
    userid: state.LoginReducer.userid,
    isShowroomDesativaLoading: state.ShowroomReducer.isShowroomDesativaLoading,
    isShowroomDesativaLocalizacaoLoading: state.ShowroomReducer.isShowroomDesativaLocalizacaoLoading,
    isShowroomLocalizacaoLoading: state.ShowroomReducer.isShowroomLocalizacaoLoading,
    showroomalocalizacao: state.ShowroomReducer.showroomalocalizacao,
    cdatual: state.ShowroomReducer.cdatual
});

export default withRouter(connect(mapStateToProps, {
    showroomDesativaLocalizacaoAction,
    showroomListAction,
    showroomCDAction,
    showroomAuditoriaAction
})(ModalDesativaExpositor));