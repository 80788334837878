import * as Api from '../utils/API';
import {
    ESPACOTRACK_EXCLUIR_NOTA,
    ESPACOTRACK_EXCLUIR_NOTA_LOADING,
    ESPACOTRACK_LIST,
    ESPACOTRACK_LOADING,
    ESPACOTRACK_PARCEIRO,
    ESPACOTRACK_PARCEIRO_LOADING,
    ESPACOTRACK_REMOVE,
    ESPACOTRACK_SHOW_OCULT,
    ESPACOTRACK_VENDEDORES,
} from '../actions/ActionsTypes';

export const EspacoTrackListAction = (userid, showOcult) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ESPACOTRACK_LOADING, payload: true });
        Api.getPedidos(userid, showOcult).then(dados => {
            dispatch({ type: ESPACOTRACK_LIST, payload: dados })
            dispatch({ type: ESPACOTRACK_LOADING, payload: false })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });

export const EspacoTrackVendedoresAction = (userid) => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getVendedores(userid).then(dados => {
            dispatch({ type: ESPACOTRACK_VENDEDORES, payload: dados })
            dispatch({ type: ESPACOTRACK_LOADING, payload: false })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });
export const EspacoTrackParceiroAction = (nunota, userid) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ESPACOTRACK_PARCEIRO_LOADING, payload: true })
        Api.getParceiro(nunota, userid).then(dados => {
            dispatch({ type: ESPACOTRACK_PARCEIRO, payload: dados })
            dispatch({ type: ESPACOTRACK_PARCEIRO_LOADING, payload: false })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });

export const EspacoTrackExcluirAction = (nunota, ordemcarga, userid) => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.postExcluirNota(nunota, ordemcarga, userid).then(dados => {
            dispatch({ type: ESPACOTRACK_EXCLUIR_NOTA, payload: dados })
            // dispatch({ type: ESPACOTRACK_REMOVE, payload: dados });
            EspacoTrackListAction(userid)
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });

export const EspacoTrackShowOcult = (show) => (dispatch) => {


    dispatch({ type: ESPACOTRACK_SHOW_OCULT, payload: show === 1 ? 1 : 0 })
}

export const removerEntregaLocal = (nunota) => (dispatch) => {
    new Promise(function (resolve, reject) {
        console.log('chegou aqui nota ->', nunota)
        dispatch({ type: ESPACOTRACK_REMOVE, payload: nunota });
    });
};
