import * as Api from '../utils/API';
import {
    SHOWROOM_LIST,
    SHOWROOM_CD,
    SHOWROOM_LIST_LOADING,
    SHOWROOM_INFO_SKU,
    SHOWROOM_ATRIBUTOS,
    SHOWROOM_INFO_SKU_LOADING,
    SHOWROOM_SAVE_LOADING,
    SHOWROOM_LOCALIZACAO,
    SHOWROOM_LOCALIZACAO_LOADING,
    SHOWROOM_CRIALOC_LOADING,
    SHOWROOM_DESATIVALOC_LOADING,
    SHOWROOM_DESATIVA_LOADING,
    SHOWROOM_LIST_AUDITORIA
} from '../actions/ActionsTypes'



export const showroomListAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: SHOWROOM_LIST_LOADING, payload: true })

        console.log('chamou list', entidade)

        Api.getListaItensLoja(entidade).then(dados => {
            dispatch({ type: SHOWROOM_LIST, payload: dados })
            dispatch({ type: SHOWROOM_LIST_LOADING, payload: false })
            resolve('OK');
        }).catch(error => {
            reject(error);
        })
    });

export const showroomChangeCDAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: SHOWROOM_CD, payload: entidade })
        resolve('OK');
    });


export const showroomInfoSKUAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // console.log(entidade)
        dispatch({ type: SHOWROOM_INFO_SKU_LOADING, payload: true })


        Api.getInfoSKU(entidade).then(dados => {
            console.log(dados)
            dispatch({ type: SHOWROOM_INFO_SKU, payload: dados.DataSource1 })
            dispatch({ type: SHOWROOM_ATRIBUTOS, payload: dados.DataSource2 })
            dispatch({ type: SHOWROOM_INFO_SKU_LOADING, payload: false })
            let data = {
                nome: dados.DataSource1[0].descprod,
                preco: dados.DataSource1[0].preco,
            }

            resolve(data);
        }).catch(error => {
            reject(error);
        })
    });


export const showroomCDAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // console.log(entidade)
        dispatch({ type: SHOWROOM_LOCALIZACAO_LOADING, payload: true })


        Api.getLocalizacaoCD(entidade).then(dados => {
            console.log(dados)
            dispatch({ type: SHOWROOM_LOCALIZACAO, payload: dados })
            dispatch({ type: SHOWROOM_LOCALIZACAO_LOADING, payload: false })
            resolve([]);
        }).catch(error => {
            reject(error);
        })
    });


export const showroomSaveNewProdAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // console.log(entidade)
        dispatch({ type: SHOWROOM_SAVE_LOADING, payload: true })


        Api.postSaveNewSKU(entidade).then(dados => {
            dispatch({ type: SHOWROOM_SAVE_LOADING, payload: false })
            resolve('OK');
        }).catch(error => {
            reject(error);
        })
    });


export const showroomDesativaSKUAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // console.log(entidade)
        dispatch({ type: SHOWROOM_DESATIVA_LOADING, payload: true })


        Api.postDesativaSKU(entidade).then(dados => {
            dispatch({ type: SHOWROOM_DESATIVA_LOADING, payload: false })
            resolve('OK');
        }).catch(error => {
            reject(error);
        })
    });


export const showroomCriaLocalizacaoAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: SHOWROOM_CRIALOC_LOADING, payload: true })

        console.log(entidade)
        Api.postCriaLocalizacao(entidade).then(dados => {
            dispatch({ type: SHOWROOM_CRIALOC_LOADING, payload: false })
            resolve('OK');

        }).catch(error => {
            reject(error);
        })
    });


export const showroomDesativaLocalizacaoAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: SHOWROOM_DESATIVALOC_LOADING, payload: true })

        console.log(entidade)
        Api.postDesativaLocalizacao(entidade).then(dados => {
            dispatch({ type: SHOWROOM_DESATIVALOC_LOADING, payload: false })
            resolve('OK');

        }).catch(error => {
            reject(error);
        })
    });



export const showroomCheckboxAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        Api.postCheckbox(entidade).then(dados => {

            resolve('OK');
        }).catch(error => {
            reject(error);
        })
    });


export const showroomAuditoriaAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        Api.getAuditoria(entidade).then(dados => {
            dispatch({ type: SHOWROOM_LIST_AUDITORIA, payload: dados })
            resolve('OK');
        }).catch(error => {
            reject(error);
        })
    });

export const showroomAuditoriaCheckAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        Api.postAuditoriaCheck(entidade).then(dados => {
            resolve('OK');
        }).catch(error => {
            reject(error);
        })
    });

