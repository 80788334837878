import {
    ESPACOTRACK_EXCLUIR_NOTA,
    ESPACOTRACK_EXCLUIR_NOTA_LOADING,
    ESPACOTRACK_LIST,
    ESPACOTRACK_LOADING,
    ESPACOTRACK_PARCEIRO,
    ESPACOTRACK_PARCEIRO_LOADING,
    ESPACOTRACK_REMOVE,
    ESPACOTRACK_SHOW_OCULT,
    ESPACOTRACK_VENDEDORES,
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    entregas: [],
    vendedores: [],
    isloading: true,
    parceiro: [],
    isloadingParceiro: false,
    exluirNota: [],
    showOcult: 0,
    isLoadingExcluir: true
}

export default (state = INITIAL_STATE, action) => {


    switch (action.type) {

        case ESPACOTRACK_LIST:
            return { ...state, entregas: action.payload }
        case ESPACOTRACK_LOADING:
            return { ...state, isloading: action.payload }
        case ESPACOTRACK_VENDEDORES:
            return { ...state, vendedores: action.payload }
        case ESPACOTRACK_PARCEIRO:
            return { ...state, parceiro: action.payload }
        case ESPACOTRACK_PARCEIRO_LOADING:
            return { ...state, isloadingParceiro: action.payload }
        case ESPACOTRACK_EXCLUIR_NOTA:
            return { ...state, exluirNota: action.payload }
        case ESPACOTRACK_EXCLUIR_NOTA_LOADING:
            return { ...state, isLoadingExcluir: action.payload }
        case ESPACOTRACK_SHOW_OCULT:
            return { ...state, showOcult: action.payload }
        case ESPACOTRACK_REMOVE:
            console.log('chegou aqui nota reducer', action.payload);
            return {
                ...state,
                entregas: state.entregas.filter((entrega) => entrega.nunota !== action.payload),
            };

        default:
            return state;
    }
}