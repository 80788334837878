import { combineReducers } from 'redux';
import OrdemCargaReducer from './OrdemCargaReducer';
import LoginReducer from './LoginReducer';
import RoboDePrecosReducer from './RoboDePrecosReducer';
import GraficoDeVendasReducer from './GraficoDeVendasReducer';
import PesquisaReducer from './PesquisaReducer';
import DespesasDeViagensReducer from './DespesasDeViagensReducer'
import ConsultoresReducer from './ConsultoresReducer'
import RenegociacaoDeDividasReducer from './RenegociacaoDeDividasReducer'
import MarketPlacesReducer from './MarketPlacesReducer'
import ComparativoProdutosReducer from './ComparativoProdutosReducer'
import ComissoesReducer from './ComissoesReducer'
import CadastroDeProdutosReducer from './CadastroDeProdutosReducer'
import ProcessamentosReducer from './ProcessamentosReducer'
import AgrupadorReducer from './AgrupadorReducer'
import BlackFridayReducer from './BlackFridayReducer'
import CorporativoReducer from './CorporativoReducer'
import InstaladoresReducer from './InstaladoresReducer'
import SerasaReducer from './SerasaReducer'
import OrcamentosReducer from './OrcamentosReducer'
import MercadoLivreReducer from './MercadoLivreReducer'
import BankReducer from './BankReducer'
import PenteFinoReducer from './PenteFinoReducer'
import B2BReducer from './B2BReducer';
import AppEntregasReducer from './AppEntregasReducer';
import TrackersReducer from './TrackersReducer';
import PermissoesReducer from './PermissoesReducer';
import PixReducer from './PixReducer';
import MonitoramentoReducer from './MonitoramentoReducer';
import KpiVisitasReducer from './KpiVisitasReducer';
import AssistenciaReducer from './AssistenciaReducer';
import OuvidoriaReducer from './OuvidoriaReducer'
import ShowroomReducer from './ShowroomReducer';
import EspacoTrackReducer from './EspacoTrackReducer'


export default combineReducers({
    OrdemCargaReducer
    , LoginReducer
    , RoboDePrecosReducer
    , GraficoDeVendasReducer
    , PesquisaReducer
    , DespesasDeViagensReducer
    , ConsultoresReducer
    , RenegociacaoDeDividasReducer
    , MarketPlacesReducer
    , ComparativoProdutosReducer
    , ComissoesReducer
    , CadastroDeProdutosReducer
    , ProcessamentosReducer
    , AgrupadorReducer
    , BlackFridayReducer
    , CorporativoReducer
    , InstaladoresReducer
    , SerasaReducer
    , OrcamentosReducer
    , MercadoLivreReducer
    , BankReducer
    , PenteFinoReducer
    , B2BReducer
    , AppEntregasReducer
    , TrackersReducer
    , PermissoesReducer
    , PixReducer
    , MonitoramentoReducer
    , KpiVisitasReducer
    , AssistenciaReducer
    , OuvidoriaReducer
    , ShowroomReducer
    , EspacoTrackReducer
})

