import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ouvidoriaOuvidoriaListAction, ouvidoriaDenunciaUpdateStatusAction, ouvidoriaOuvidoriaCloseServiceAction, ouvidoriaOuvidoriaSaveAction } from '../../actions/OuvidoriaActions';

import {
    Tab, Table, Button, Icon, Pagination, Modal, ModalHeader, ModalContent, ModalDescription, ModalActions, Form, TextArea
} from 'semantic-ui-react'


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}
`;

const CharCounter = styled.div`
  position: absolute;
  bottom: 8px;
  right: 10px;
  font-size: 12px;
  color: #666;
`;

function ListOuvidoria(props) {

    const { ouvidoriaOuvidoriaListAction, ouvidoriaDenunciaUpdateStatusAction, ouvidoriaOuvidoriaCloseServiceAction, ouvidoriaOuvidoriaSaveAction } = props;// actions
    const { listOuvidoria, userid, loadingOuvidoria } = props;// reducers


    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [open, setOpen] = useState(false);
    const [ouvidoriaClicado, setouvidoriaClicado] = useState([]);
    const [textareaValue, setTextareaValue] = useState('');
    const [loading, setIsLoading] = useState(false);
    const [loadingOnClose, setIsLoadingOnClose] = useState(false);
    const [loadingCloseService, setIsLoadingCloseService] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setIsLoading(false);
        setError(false);
        ouvidoriaOuvidoriaListAction(activePage).then((dados) => {
            let page_size = 20;
            let totalpages = Math.ceil(dados[0]?.qnt_ouvidoria / page_size);
            setTotalPages(totalpages)
        })
        console.log('chamou aqui')
    }, [])

    const handlePageChange = (e, { activePage }) => {
        setActivePage(activePage); // Atualiza o estado com a nova página ativa
        console.log(`Página ${activePage}`); // Exibe o número da página no console
        // Aqui você pode adicionar a lógica para buscar os dados da nova página, se necessário

        ouvidoriaOuvidoriaListAction(activePage).then((data) => {
            let page_size = 20;
            let totalpages = Math.ceil(data[0]?.qnt_ouvidoria / page_size);
            setTotalPages(totalpages)
        });
        console.log('chamou aqui 1')
    };


    const onOpenPopup = (dados) => {
        setOpen(true)
        setouvidoriaClicado(dados)
        if (dados.status == 0 || dados.status == 1) {
            ouvidoriaDenunciaUpdateStatusAction(dados.protocolo)
        }
        console.log(dados)
    };

    const handleTextareaChange = (e) => {
        setTextareaValue(e.target.value);
    };

    const onClose = () => {
        setIsLoadingOnClose(true)
        ouvidoriaOuvidoriaListAction(1).then((dados) => {
            let page_size = 20;
            let totalpages = Math.ceil(dados[0]?.qnt_ouvidoria / page_size);
            setTotalPages(totalpages)
            setIsLoading(false)
            setIsLoadingOnClose(false)
            setOpen(false)
        })
        setError(false);
        setTextareaValue('');
    }

    const handleCloseService = () => {
        if (textareaValue !== '') {
            setError(true);
            setIsLoading(false);
            return;
        }

        setIsLoadingCloseService(true)
        ouvidoriaOuvidoriaCloseServiceAction(ouvidoriaClicado.protocolo).then((dados) => {
            ouvidoriaOuvidoriaListAction(1).then((dados) => {
                let page_size = 20;
                let totalpages = Math.ceil(dados[0]?.qnt_ouvidoria / page_size);
                setTotalPages(totalpages)
                setIsLoading(false)
                setIsLoadingCloseService(false)
                setOpen(false)
            })
            setError(false);
            setTextareaValue('');
        })

    }

    const handleSave = (dados) => {
        setIsLoading(true);
        if (textareaValue == '') {
            setError(true);
            setIsLoading(false);
            return;
        }


        const data = {
            id: dados,
            resposta: textareaValue,
            userid: userid
        }
        console.log(data)
        setTextareaValue('');


        ouvidoriaOuvidoriaSaveAction(data).then(() => {
            ouvidoriaOuvidoriaListAction(1).then((dados) => {
                let page_size = 20;
                let totalpages = Math.ceil(dados[0]?.qnt_ouvidoria / page_size);
                setTotalPages(totalpages)
                setIsLoading(false)
                setOpen(false)
            })
        })


    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                closeOnDimmerClick={false}
            >
                <ModalHeader style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '70%', textAlign: 'left' }}>
                        <strong>Protocolo:</strong> {ouvidoriaClicado.protocolo}
                        <div style={{ fontSize: '12px', color: '#555' }}>
                            <strong>Data da abertura:</strong>{' '}
                            {ouvidoriaClicado.dtabertura
                                ? new Date(ouvidoriaClicado.dt_mensagem).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                })
                                : ' '}
                        </div>
                    </div>

                    <div style={{ marginTop: '10px', width: '100%' }}>
                        <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                            {ouvidoriaClicado.nomeparc} ({ouvidoriaClicado.codparc})
                        </span>
                    </div>
                </ModalHeader>
                <ModalContent>
                    <ModalDescription>
                        <>
                            <div key={ouvidoriaClicado.ouvidoriaid} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ flex: '1 1 50%', padding: '5px' }}>
                                    <section style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ paddingBottom: '15px' }}>
                                            <span style={{ display: 'block', paddingBottom: '5px' }}><strong>Relato: </strong> {ouvidoriaClicado.mensagem}</span>

                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ width: '50%', paddingRight: '10px' }}>
                                                <h3>Informações do cliente</h3>
                                                <span style={{ display: 'block', paddingBottom: '5px' }}><strong>CPF/CNPJ: </strong> {ouvidoriaClicado.cpf_cnpj}</span>
                                                <span style={{ display: 'block', paddingBottom: '5px' }}><strong>Email: </strong> {ouvidoriaClicado.email}</span>
                                                <span style={{ display: 'block', paddingBottom: '5px' }}><strong>Telefone: </strong> {ouvidoriaClicado.telefone}</span>
                                                <span style={{ display: 'block', paddingBottom: '5px' }}><strong>NF: </strong> {ouvidoriaClicado.nf}</span>
                                                <span style={{ display: 'block', paddingBottom: '5px' }}><strong>Responsável: </strong> {ouvidoriaClicado.responsavel}</span>

                                                {ouvidoriaClicado.status == 2 ?
                                                    (<>
                                                        <span style={{
                                                            display: 'block', maxWidth: '800px', whiteSpace: 'pre-wrap',
                                                            wordBreak: 'break-all',
                                                        }}><strong>Resposta:</strong> {ouvidoriaClicado.resposta}</span><br></br>
                                                        <span style={{ display: 'block' }}><strong>Data da Resposta: </strong>{ouvidoriaClicado.dt_resposta ? new Date(ouvidoriaClicado.dt_mensagem).toLocaleDateString('pt-BR', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric',
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            second: '2-digit'
                                                        }) : ' '} </span><br></br>
                                                    </>
                                                    )
                                                    : ('')}
                                            </div>
                                            <div style={{
                                                backgroundColor: '#e3e3e3',
                                                width: '60%',
                                                padding: '10px',
                                                borderRadius: '5px',
                                                maxHeight: '200px', // Altura fixa
                                                overflowY: 'auto' // Scroll quando necessário
                                            }}>
                                                <span style={{ display: 'block', paddingBottom: '5px' }}
                                                    dangerouslySetInnerHTML={{ __html: ouvidoriaClicado.historico }}>
                                                </span>
                                            </div>
                                        </div>


                                    </section>
                                    <hr></hr>
                                    {ouvidoriaClicado.status == 0 || ouvidoriaClicado.status == 1 || ouvidoriaClicado.status == 4 || ouvidoriaClicado.status == 3 ?
                                        (<>
                                            <Form>
                                                <label>Resposta:</label>
                                                <Form.Group widths='equal'>

                                                    <Form.Field
                                                        control={TextArea}
                                                        placeholder='Digite...'
                                                        onChange={handleTextareaChange}
                                                        value={textareaValue}
                                                        maxLength={1200}
                                                        error={error}
                                                    // rows='9'
                                                    />

                                                </Form.Group>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    {textareaValue.length}/1200
                                                </div>
                                            </Form>
                                        </>
                                        ) : ('')
                                    }
                                </div>
                            </div>

                        </>

                    </ModalDescription>
                </ModalContent >
                <ModalActions>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div> {ouvidoriaClicado.status == 0 || ouvidoriaClicado.status == 1 || ouvidoriaClicado.status == 4 || ouvidoriaClicado.status == 3 ?
                            (<Button color='blue' onClick={() => handleCloseService()} loading={loadingCloseService}>
                                Encerrar Atendimento
                            </Button>) : ('')}

                        </div>
                        <div>
                            <Button onClick={() => onClose()} color='red' loading={loadingOnClose}>
                                Fechar
                            </Button>
                            {ouvidoriaClicado.status == 0 || ouvidoriaClicado.status == 1 || ouvidoriaClicado.status == 4 || ouvidoriaClicado.status == 3 ?
                                (<Button onClick={() => handleSave(ouvidoriaClicado.ouvidoriaid)} color='green' loading={loading}>
                                    Responder
                                </Button>) : ('')}
                        </div>
                    </div>

                </ModalActions>
            </Modal >
            <Tab.Pane loading={loadingOuvidoria}>
                <div>
                    <StyledTable>
                        <StyledTableHeader>
                            <Table.Row style={{ textAlign: 'center' }}>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Protocolo</Table.HeaderCell>
                                <Table.HeaderCell>Nome</Table.HeaderCell>
                                <Table.HeaderCell>Data da Abertura</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Ação</Table.HeaderCell>
                            </Table.Row>
                        </StyledTableHeader>

                        {listOuvidoria.map((item) => (
                            <StyledTableBody style={{ textAlign: 'center' }} >
                                <Table.Cell>{item.ouvidoriaid}</Table.Cell>
                                <Table.Cell>{item.protocolo}</Table.Cell>
                                <Table.Cell>{item.nomeparc} ({item.codparc})</Table.Cell>
                                <Table.Cell>
                                    {new Date(item.dtabertura).toLocaleDateString('pt-BR', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}
                                </Table.Cell>
                                <Table.Cell>{item.status == 0 ? 'Pendente' : item.status == 1 ? 'Em Analise' : item.status == 2 ? 'Concluído' : item.status == 3 ? 'Aguardando Cliente' : item.status == 4 ? 'Aguardando Ouvidoria' : ''}</Table.Cell>
                                <Table.Cell>
                                    <Button color='green'
                                        onClick={() => onOpenPopup(item)}
                                    >
                                        <Icon name='search' />
                                        Analisar
                                    </Button>
                                </Table.Cell>
                            </StyledTableBody>

                        ))}
                    </StyledTable>
                    <div style={{ textAlign: 'right', marginTop: '15px' }}>
                        <Pagination
                            boundaryRange={0}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={1}
                            lastItem={totalPages}
                            siblingRange={1}
                            totalPages={totalPages}
                            activePage={activePage} // Vincula o estado da página ativa
                            onPageChange={handlePageChange} // Chama o manipulador ao mudar de página
                        />
                    </div>
                </div>
            </Tab.Pane>
        </>
    );
}


const mapStateToProps = (state) => ({
    listOuvidoria: state.OuvidoriaReducer.listOuvidoria,
    loadingOuvidoria: state.OuvidoriaReducer.loadingOuvidoria,
    userid: state.LoginReducer.userid
})
export default withRouter(connect(mapStateToProps, {
    ouvidoriaOuvidoriaListAction,
    ouvidoriaDenunciaUpdateStatusAction,
    ouvidoriaOuvidoriaCloseServiceAction,
    ouvidoriaOuvidoriaSaveAction
}
)(ListOuvidoria));
