import {
    SHOWROOM_LIST,
    SHOWROOM_CD,
    SHOWROOM_LIST_LOADING,
    SHOWROOM_INFO_SKU,
    SHOWROOM_ATRIBUTOS,
    SHOWROOM_LOCALIZACAO,
    SHOWROOM_INFO_SKU_LOADING,
    SHOWROOM_SAVE_LOADING,
    SHOWROOM_DESATIVA_LOADING,
    SHOWROOM_LOCALIZACAO_LOADING,
    SHOWROOM_CRIALOC_LOADING,
    SHOWROOM_DESATIVALOC_LOADING,
    SHOWROOM_LIST_AUDITORIA
} from '../actions/ActionsTypes';


const INITIAL_STATE = {
    cdatual: "",
    showroomlist: [],
    showroominfosku: [],
    showroomatributos: [],
    showroomauditoria: [],
    showroomalocalizacao: [],
    isShowroomListLoading: false,
    isShowroomInfoLoading: false,
    isShowroomSaveLoading: false,
    isShowroomDesativaLoading: false,
    isShowroomLocalizacaoLoading: false,
    isShowroomCriaLocalizacaoLoading: false,
    isShowroomDesativaLocalizacaoLoading: false,
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SHOWROOM_LIST:
            return { ...state, showroomlist: action.payload }

        case SHOWROOM_CD:
            return { ...state, cdatual: action.payload }

        case SHOWROOM_LIST_LOADING:
            return { ...state, isShowroomListLoading: action.payload }

        case SHOWROOM_CRIALOC_LOADING:
            return { ...state, isShowroomCriaLocalizacaoLoading: action.payload }

        case SHOWROOM_DESATIVALOC_LOADING:
            return { ...state, isShowroomDesativaLocalizacaoLoading: action.payload }

        case SHOWROOM_INFO_SKU:
            return { ...state, showroominfosku: action.payload }

        case SHOWROOM_ATRIBUTOS:
            return { ...state, showroomatributos: action.payload }

        case SHOWROOM_LOCALIZACAO:
            return { ...state, showroomalocalizacao: action.payload }

        case SHOWROOM_LOCALIZACAO_LOADING:
            return { ...state, isShowroomLocalizacaoLoading: action.payload }


        case SHOWROOM_INFO_SKU_LOADING:
            return { ...state, isShowroomInfoLoading: action.payload }

        case SHOWROOM_SAVE_LOADING:
            return { ...state, isShowroomSaveLoading: action.payload }

        case SHOWROOM_DESATIVA_LOADING:
            return { ...state, isShowroomDesativaLoading: action.payload }

        case SHOWROOM_LIST_AUDITORIA:
            return { ...state, showroomauditoria: action.payload }

        default:
            return state;
    }
}