import { useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { showroomListAction, showroomAuditoriaAction } from '../../actions/ShowroomActions';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  &&& {
    border: none;
    width: 100%;
    text-align: center;
  }
`;

const StyledTableHeader = styled(Table.Header)`
  &&& th {
    border: none !important;
    font-weight: bold;
    padding: 0px 0;
    text-align: center;
  }

  @media print {
    * {
  font-weight: bold !important;
  font-size: 12px !important;
  background-color: gray !important;
  }
`;

const StyledTableBody = styled(Table.Body)`
  &&& td {
    border: none !important;
    padding: 0px 0 !important;
    text-align: center;
  }

  tr:nth-child(even) {
    background-color: #add8e6 !important;;
    print-color-adjust: exact; 
    -webkit-print-color-adjust: exact;
  }
`;



const PrintContainer = styled.div`

.print-only {
  display: none;
}


@media print {
  * {
  border-collapse: collapse !important;
}


.print-only {
  margin-top: 15px !important;
}

body {
  font-size: 9px !important;
  line-height: 0.5 !important
  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}



  table {
    width: 100% !important;
    font-size: 9px !important;
  }

  th, td {
    padding: 0px 0px !important;
  }

  tr {
    height: auto !important;
  }

  .print-only {
    display: block !important;
    margin-top: 5px !important;
    padding-top: 5px !important; /* Adiciona um espaço extra se a margem for zerada */
  }

  .show-on-screen {
    display: none !important;
  }

  tr:nth-child(even) {
    background-color: #add8e6 !important;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .print-container {
    height: 100vh;
    max-height: 29.7cm;
    overflow: hidden;
  }

  .StyledTable {
    table-layout: fixed;
  }

  .hide-on-print {
    display: none !important;
  }

  table {
    page-break-inside: avoid !important;
  }

  tr {
    page-break-before: auto;
    page-break-after: auto;
  }
}
`;

const ShowroomFolha = ({ showroomListAction, showroomlist, selectedLocal, cdatual, showroomAuditoriaAction }) => {
  useEffect(() => {
    showroomListAction(cdatual);
    showroomAuditoriaAction()
  }, []);


  const filteredList = showroomlist.filter(item => item.local === selectedLocal);
  const versaoFinal = filteredList.length > 0 ? filteredList[0].versao : "N/A";

  // Verifica se há pelo menos um item com cada atributo
  const hasDescAtributo1 = filteredList.some(item => item.desc_atributo1);
  const hasAtributo1 = filteredList.some(item => item.atributo1);
  const hasDescAtributo2 = filteredList.some(item => item.desc_atributo2);
  const hasAtributo2 = filteredList.some(item => item.atributo2);
  const hasDescAtributo3 = filteredList.some(item => item.desc_atributo3);
  const hasAtributo3 = filteredList.some(item => item.atributo3);

  const itemCount = filteredList.length;
  // Define dinamicamente o tamanho da fonte e o padding
  const dynamicFontSize = itemCount > 15 ? "9px" : `${Math.min(15, 9 + (15 - itemCount) * 0.8)}px`;
  const dynamicPadding = itemCount > 15 ? "2px 10px" : `${Math.min(6, 2 + (15 - itemCount) * 2)}px 10px`;


  return (
    <>
      {selectedLocal && (
        <div>
          <PrintContainer>
            {/* Versão para TELA */}
            <div className="show-on-screen">
              <StyledTable celled compact="very">
                <StyledTableHeader>
                  <Table.Row>
                    <Table.HeaderCell width="1">SKU</Table.HeaderCell>
                    <Table.HeaderCell width="3">Nome</Table.HeaderCell>
                    <Table.HeaderCell width="1">Vendido por</Table.HeaderCell>
                    {hasDescAtributo1 && <Table.HeaderCell className="hide-on-print" width="1">Desc. Atributo 1</Table.HeaderCell>}
                    {hasAtributo1 && <Table.HeaderCell className="hide-on-print" width="1">Atributo 1</Table.HeaderCell>}
                    {hasDescAtributo2 && <Table.HeaderCell className="hide-on-print" width="1">Desc. Atributo 2</Table.HeaderCell>}
                    {hasAtributo2 && <Table.HeaderCell className="hide-on-print" width="1">Atributo 2</Table.HeaderCell>}
                    <Table.HeaderCell width="1">Valor</Table.HeaderCell>
                    <Table.HeaderCell className="hide-on-print" width="1">Dt Atualização</Table.HeaderCell>
                    <Table.HeaderCell className="hide-on-print" width="1">Versão</Table.HeaderCell>
                  </Table.Row>
                </StyledTableHeader>

                <StyledTableBody>
                  {filteredList.map((item, idx) => (
                    <Table.Row key={idx}>
                      <Table.Cell>{item.codprod}</Table.Cell>
                      <Table.Cell>{item.descprod}</Table.Cell>
                      <Table.Cell className="hide-on-print">{item.volume}</Table.Cell>
                      {hasDescAtributo1 && <Table.Cell className="hide-on-print">{item.desc_atributo1 || ''}</Table.Cell>}
                      {hasAtributo1 && <Table.Cell className="hide-on-print">{item.atributo1 || ''}</Table.Cell>}
                      {hasDescAtributo2 && <Table.Cell className="hide-on-print">{item.desc_atributo2 || ''}</Table.Cell>}
                      {hasAtributo2 && <Table.Cell className="hide-on-print">{item.atributo2 || ''}</Table.Cell>}
                      <Table.Cell>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(item.preco_versao)}</Table.Cell>
                      <Table.Cell className="hide-on-print">
                        {new Date(item.dtpreco_versao).toLocaleDateString('pt-BR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })}
                      </Table.Cell>
                      <Table.Cell className="hide-on-print">{item.versao || ''}</Table.Cell>
                    </Table.Row>
                  ))}
                </StyledTableBody>
              </StyledTable>

            </div>

            {/* Versão para IMPRESSÃO */}
            <div className="print-only">
              <StyledTable celled compact="very">
                <StyledTableHeader >
                  <Table.Row>
                    <Table.HeaderCell width="1">SKU</Table.HeaderCell>
                    <Table.HeaderCell width="3">Nome</Table.HeaderCell>
                    {hasDescAtributo2 && <Table.HeaderCell width="1"></Table.HeaderCell>}
                    <Table.HeaderCell width="1">Valor</Table.HeaderCell>
                  </Table.Row>
                </StyledTableHeader>
                <StyledTableBody>
                  {filteredList.map((item, idx) => (
                    <Table.Row key={idx} style={{ fontSize: dynamicFontSize }}>
                      <Table.Cell style={{ padding: dynamicPadding }}>{item.codprod}</Table.Cell>
                      <Table.Cell style={{ padding: dynamicPadding }}>
                        <div style={{ lineHeight: '1.1', marginBottom: '0' }}>
                          {item.descprod} {item.atributo1 ? `(${item.atributo1})` : ''}
                        </div>
                        {item.frase && item.rendimento && (
                          <div style={{ fontSize: '0.8em', color: '#666', lineHeight: '1.1', marginTop: '0' }}>
                            {item.frase + item.rendimento}
                          </div>
                        )}
                      </Table.Cell>
                      {hasDescAtributo2 && (
                        <Table.Cell style={{ lineHeight: dynamicPadding }}>{item.atributo2 || ''}</Table.Cell>
                      )}
                      <Table.Cell style={{ lineHeight: dynamicPadding }}>
                        <div style={{ fontSize: '1.2em', fontWeight: 'bold', lineHeight: '1.1' }}>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.precocomercial)} / {item.unidadecomercial}
                        </div>
                        {item.frase && item.rendimento ? <div style={{ fontSize: '0.9em', color: '#666', lineHeight: '1.1' }}>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.precovenda)} / {item.unidadevenda}
                        </div>
                          : ''}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </StyledTableBody>
              </StyledTable>

              <div style={{ marginTop: '5px', textAlign: 'center', fontWeight: 'bold', fontSize: '14px' }}>
                Expositor: {selectedLocal} v.{versaoFinal}

              </div>
            </div>
          </PrintContainer>
        </div>
      )}
    </>
  );
};

export default withRouter(connect(state => ({
  showroomlist: state.ShowroomReducer.showroomlist,
  cdatual: state.ShowroomReducer.cdatual
}), { showroomListAction, showroomAuditoriaAction })(ShowroomFolha));
