import { useState, useEffect, useRef } from 'react';
import { Button, Tab, Icon, Table, Checkbox } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ShowroomFolha from './ShowroomFolha';
import ModalAddProduto from './ModalAddProduto'
import ModalRemoveProduto from './ModalRemoveProduto'
import ModalAddExpositor from './ModalAddExpositor'
import ModalDesativaExpositor from './ModalDesativaExpositor';
import ShowroomAuditoria from './ShowroomAuditoria';
import { showroomListAction, showroomChangeCDAction, showroomCheckboxAction, showroomAuditoriaAction, showroomAuditoriaCheckAction } from '../../actions/ShowroomActions';
import styled from 'styled-components';
import ReactToPrint from "react-to-print";
import './ShowroomList.css';



const GridContainer = styled.div`
  column-count: 6;
  column-gap: 15px;
  margin: auto;

  div {
    display: inline-block;
    width: 100%;
    break-inside: avoid;
    background-color: #f1f1f1;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

function ShowroomList(props) {
    const { showroomListAction, showroomAuditoriaAction, showroomAuditoriaCheckAction, showroomlist, isShowroomListLoading, showroomChangeCDAction, userid, showroomCheckboxAction, cdatual } = props;
    const uniqueLocals = [...new Set(showroomlist?.map(item => item.local))].slice(0, 30);
    const uniqueLocals2 = [...new Map(showroomlist.map(item => [item.local, { local: item.local, idsr: item.idsr }])).values()].slice(0, 30);
    const [openModalAddProduto, setOpenModalAddProduto] = useState(false);
    const [openModalRemoveProduto, setOpenModalRemoveProduto] = useState(false);
    const [openModalAddExpositor, setOpenModalAddExpositor] = useState(false);
    const [openModalDesativaExpositor, setOpenModalDesativaExpositor] = useState(false);
    const printRef = useRef();
    const printRefAuditoria = useRef();
    const [checkboxState, setCheckboxState] = useState({});
    const [alertState, setAlertState] = useState({});
    // Definindo um local inicial
    const [selectedLocal, setSelectedLocal] = useState(uniqueLocals[0] || null);
    const [selectedLocalID, setSelectedLocalID] = useState(null);

    useEffect(() => {
        showroomListAction('BOA VISTA');
        showroomChangeCDAction('BOA VISTA')
        showroomAuditoriaAction()
    }, []);

    useEffect(() => {
        const newCheckboxState = {};
        const newAlertState = {};

        uniqueLocals.forEach(local => {
            const impressoMarcado = showroomlist.some(item => item.local === local && item.impresso === "Sim");
            const conferidoMarcado = showroomlist.some(item => item.local === local && item.conferido === "Sim");
            const localData = showroomlist.find(item => item.local === local) || {};

            newCheckboxState[local] = {
                impresso: impressoMarcado,
                conferido: conferidoMarcado,
                userimpresso: localData.userimpresso || '',
                dtimpresso: localData.dtimpresso || '',
                userconferido: localData.userconferido || '',
                dtconferido: localData.dtconferido || '',
            };

            newAlertState[local] = !(impressoMarcado && conferidoMarcado);
        });

        setCheckboxState(newCheckboxState);
        setAlertState(newAlertState);
    }, [showroomlist]);

    // const componentRefDevolucao = useRef();
    let componentRefDevolucao = '';

    const shouldDisplayColumn = (attribute) => showroomlist.some(item => item[attribute]);

    const changeCD = (cdatual) => {
        console.log(cdatual);
        let newcd = cdatual.toUpperCase() === 'EUCLIDES' ? 'BOA VISTA' : 'EUCLIDES';
        showroomChangeCDAction(newcd)
        // console.log(newcd);

        showroomListAction(newcd);
    }

    const handleAddProduto = () => {
        setOpenModalAddProduto(true);
    }

    const handleRemoveProduto = () => {
        setOpenModalRemoveProduto(true);
    }

    const handleAddExpositor = () => {
        setOpenModalAddExpositor(true);
    }
    const handleDesativaExpositor = () => {
        setOpenModalDesativaExpositor(true);
    }


    const filteredList = showroomlist.filter(item => item.local === selectedLocal);

    const columnsToShow = {
        desc_atributo1: filteredList.some(item => item.desc_atributo1),
        atributo1: filteredList.some(item => item.atributo1),
        desc_atributo2: filteredList.some(item => item.desc_atributo2),
        atributo2: filteredList.some(item => item.atributo2),
        desc_atributo3: filteredList.some(item => item.desc_atributo3),
        atributo3: filteredList.some(item => item.atributo3),
    };

    const handleCheckboxChange = (local, field) => {
        setCheckboxState(prevState => {
            const wasChecked = prevState[local]?.[field] || false; // Estado anterior do checkbox
            const newCheckedState = !wasChecked; // Novo estado do checkbox

            const updatedState = {
                ...prevState,
                [local]: {
                    ...prevState[local],
                    [field]: newCheckedState
                }
            };

            const impressoMarcado = updatedState[local]?.impresso || false;
            const conferidoMarcado = updatedState[local]?.conferido || false;

            // Identifica qual checkbox foi desmarcado
            const checkboxValues = [];
            if (impressoMarcado) checkboxValues.push("impresso");
            if (conferidoMarcado) checkboxValues.push("conferido");


            // JSON de envio
            const data = {
                ativo: newCheckedState ? "Sim" : "Não",
                user: userid,
                localizacao: selectedLocalID,
                alterado: field,
            };

            console.log(selectedLocalID)
            if (selectedLocalID !== null) {
                console.log(selectedLocalID)
                showroomCheckboxAction(data).then((item) => {
                    showroomListAction(cdatual);
                    showroomAuditoriaAction()
                    showroomChangeCDAction(cdatual)
                })

            }
            // Atualiza o estado do alerta
            setAlertState(prevAlert => ({
                ...prevAlert,
                [local]: !(impressoMarcado && conferidoMarcado) // Se ambos estiverem marcados, remove o alerta
            }));

            return updatedState;
        });
    };

    const checkboxImprimir = () => {

        const data = {
            ativo: "Sim",
            user: userid,
            localizacao: selectedLocalID,
            alterado: 'impresso',
        };
        if (selectedLocalID !== null) {

            console.log(selectedLocalID, 'aqui')
            showroomCheckboxAction(data).then((item) => {
                showroomListAction(cdatual);
                showroomAuditoriaAction()
                showroomChangeCDAction(cdatual)
            })
        }

    }

    const auditoria = () => {

        const data = {
            user: userid,
        };
        showroomAuditoriaCheckAction(data).then((item) => {
            showroomListAction(cdatual);
            showroomAuditoriaAction()
            showroomChangeCDAction(cdatual)
        })


    }

    console.log(selectedLocal)

    return (
        <>
            <Tab.Pane loading={isShowroomListLoading}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', width: '100%' }}>

                    <div style={{ fontSize: '10px', color: '#555', display: 'flex', gap: '10px' }}>
                        <p><strong>Data da última atualização de preço: </strong>
                            {showroomlist?.[0]?.dt_ult_att_preco}</p>
                    </div>

                    <div>
                        <Button color="red" onClick={() => handleDesativaExpositor()}>Desativar Expositor</Button>
                        <Button color="blue" onClick={() => handleAddExpositor()}>Adicionar Expositor</Button>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                    <div>
                        <ReactToPrint
                            trigger={() => <Button color="blue" disabled={!selectedLocal}>Imprimir</Button>}
                            content={() => printRef.current}
                            // onAfterPrint={() => checkboxImprimir()}
                            onAfterPrint={(e) => checkboxImprimir()}
                        />

                        <ReactToPrint
                            trigger={() => <Button color="blue" disabled={!selectedLocal}>Auditoria</Button>}
                            content={() => printRefAuditoria.current}
                            // onAfterPrint={() => checkboxImprimir()}
                            onAfterPrint={(e) => auditoria()}
                        />
                    </div>


                    <div>
                        <Button color="red" onClick={() => handleRemoveProduto()}>Remover Produto</Button>
                        <Button color="blue" onClick={() => handleAddProduto()}>Adicionar Produto</Button>
                    </div>
                </div>
                <div style={{ fontSize: '10px', color: '#555', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px', width: '100%' }}>
                    <div style={{ fontSize: '10px', color: '#555', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '10px' }}>
                        <p><strong>Usuário Auditoria:</strong> {showroomlist?.[0]?.userauditimpresso || 'N/A'}</p>
                        <p><strong>Data Auditoria:</strong>
                            {showroomlist?.[0]?.auditimpresso
                                ? new Date(showroomlist?.[0]?.auditimpresso).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit'
                                })
                                : 'N/A'}</p>
                    </div>
                    <div style={{ fontSize: '10px', color: '#555', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '10px' }}>
                        <p><strong>Usuário Impressão:</strong> {checkboxState[selectedLocal]?.userimpresso || 'N/A'}</p>
                        <p><strong>Data Impressão:</strong>
                            {checkboxState[selectedLocal]?.dtimpresso
                                ? new Date(checkboxState[selectedLocal]?.dtimpresso).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit'
                                })
                                : 'N/A'}</p>
                        <p><strong>Usuário Conferência:</strong> {checkboxState[selectedLocal]?.userconferido || 'N/A'}</p>
                        <p><strong>Data Conferência:</strong>
                            {checkboxState[selectedLocal]?.dtconferido
                                ? new Date(checkboxState[selectedLocal]?.dtconferido).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit'
                                })
                                : 'N/A'}
                        </p>
                    </div>

                </div>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center', // Alinhar tudo no topo
                        justifyContent: 'space-between',
                        backgroundColor: '#c9c9c9',
                        borderRadius: '5px',
                        height: '45px',
                        width: '100%',
                        padding: '3px 10px', // Reduzindo padding
                    }}
                >
                    <div style={{ width: '150px' }}></div> {/* Diminuído de 180px para 150px */}

                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            textAlign: 'center',
                        }}
                    >
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                            {showroomlist?.[0]?.cd}
                        </span>
                        <Icon
                            name="refresh"
                            onClick={() => changeCD(showroomlist?.[0]?.cd)}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>

                    {selectedLocal && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '3px', // Reduzindo espaço entre linhas
                                fontSize: '9px', // Diminuindo o tamanho da fonte
                                lineHeight: '1', // Reduzindo a altura das linhas
                            }}
                        >
                            <div style={{ display: 'flex', gap: '5px' }}> {/* Reduzindo gap */}
                                <Checkbox
                                    label="Impresso"
                                    checked={checkboxState[selectedLocal]?.impresso || false}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        handleCheckboxChange(selectedLocal, 'impresso');
                                    }}
                                />
                                <Checkbox
                                    label="Conferido"
                                    checked={checkboxState[selectedLocal]?.conferido || false}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        handleCheckboxChange(selectedLocal, 'conferido');
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>





                <GridContainer>
                    {uniqueLocals2.map((local, index) => (
                        <div
                            key={index}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: selectedLocal === local.local ? 'gray' : '#f1f1f1',
                                borderRadius: '5px',
                                padding: '10px',
                                outline: selectedLocal === local.local ? '2px solid black' : 'none'

                            }}
                            className={`location-box ${alertState[local.local] ? "alert-box" : ""}`}
                            onClick={() => {
                                setSelectedLocal(local.local);
                                setSelectedLocalID(local.idsr);
                            }}
                        >
                            <Icon name='map marker alternate' />
                            <div>{local.local}</div>

                        </div>
                    ))}
                </GridContainer>
                <ModalAddProduto open={openModalAddProduto} onClose={() => setOpenModalAddProduto(false)} />
                <ModalRemoveProduto open={openModalRemoveProduto} onClose={() => setOpenModalRemoveProduto(false)} />
                <ModalAddExpositor open={openModalAddExpositor} onClose={() => setOpenModalAddExpositor(false)} />
                <ModalDesativaExpositor open={openModalDesativaExpositor} onClose={() => setOpenModalDesativaExpositor(false)} />

                {selectedLocal && (
                    <div ref={printRef} >
                        <ShowroomFolha selectedLocal={selectedLocal} />
                    </div>
                )}
                <div ref={printRefAuditoria} >
                    <ShowroomAuditoria />
                </div>
            </Tab.Pane >
        </>
    );
}

const mapStateToProps = (state) => ({
    showroomlist: state.ShowroomReducer.showroomlist,
    isShowroomListLoading: state.ShowroomReducer.isShowroomListLoading,
    userid: state.LoginReducer.userid,
    cdatual: state.ShowroomReducer.cdatual
});

export default withRouter(connect(mapStateToProps, { showroomListAction, showroomAuditoriaCheckAction, showroomAuditoriaAction, showroomChangeCDAction, showroomCheckboxAction })(ShowroomList));
