import {
    OUVIDORIA_LIST_DENUNCIA,
    OUVIDORIA_LIST_LOADING_DENUNCIA,
    OUVIDORIA_LIST_OUVIDORIA,
    OUVIDORIA_LIST_LOADING_OUVIDORIA
} from '../actions/ActionsTypes';


const INITIAL_STATE = {
    listDenuncia: [],
    loadingDenuncia: false,
    listOuvidoria: [],
    loadingOuvidoria: false
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OUVIDORIA_LIST_DENUNCIA:
            return { ...state, listDenuncia: action.payload };

        case OUVIDORIA_LIST_LOADING_DENUNCIA:
            return { ...state, loadingDenuncia: action.payload };

        case OUVIDORIA_LIST_OUVIDORIA:
            return { ...state, listOuvidoria: action.payload };

        case OUVIDORIA_LIST_LOADING_OUVIDORIA:
            return { ...state, loadingOuvidoria: action.payload };

        default:
            return state;
    }
};