import {
    OUVIDORIA_LIST_DENUNCIA,
    OUVIDORIA_LIST_LOADING_DENUNCIA,
    OUVIDORIA_LIST_OUVIDORIA,
    OUVIDORIA_LIST_LOADING_OUVIDORIA
} from '../actions/ActionsTypes'
import * as Api from '../utils/API';


export const ouvidoriaDenunciaListAction = (page) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: OUVIDORIA_LIST_LOADING_DENUNCIA, payload: true })
        console.log('caiu aquiu 1')
        Api.getDenunciaList(page).then(dados => {
            dispatch({ type: OUVIDORIA_LIST_DENUNCIA, payload: dados })
            dispatch({ type: OUVIDORIA_LIST_LOADING_DENUNCIA, payload: false })
            resolve(dados)
        }).catch(error => {
            dispatch({ type: OUVIDORIA_LIST_LOADING_DENUNCIA, payload: false })
            reject(error);
        })

    });



export const ouvidoriaDenunciaSaveAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // dispatch({ type: PENTEFINO_LIST_LOADER, payload: true })
        console.log('caiu aquiu')
        Api.postDenunciaSave(dados).then(dados => {
            // dispatch({ type: OUVIDORIA_LIST, payload: dados })
            // dispatch({ type: PENTEFINO_LIST, payload: dados })
            resolve(dados)
        }).catch(error => {
            // dispatch({ type: PENTEFINO_LIST_LOADER, payload: false })
            reject(error);
        })

    });


export const ouvidoriaDenunciaUpdateStatusAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log('caiu aqi', dados)
        let data = {
            protocolo: dados
        }

        Api.postDenunciaStatusUpdate(data).then(dados => {
            resolve([])
        }).catch(error => {
            reject(error);
        })

    });




export const ouvidoriaOuvidoriaListAction = (page) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: OUVIDORIA_LIST_LOADING_OUVIDORIA, payload: true })

        Api.getOuvidoriaList(page).then(dados => {
            dispatch({ type: OUVIDORIA_LIST_OUVIDORIA, payload: dados })
            dispatch({ type: OUVIDORIA_LIST_LOADING_OUVIDORIA, payload: false })
            resolve(dados)
        }).catch(error => {
            dispatch({ type: OUVIDORIA_LIST_LOADING_OUVIDORIA, payload: false })
            reject(error);
        })

    });


export const ouvidoriaOuvidoriaUpdateStatusAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log('caiu aqi', dados)
        let data = {
            protocolo: dados
        }

        Api.postDenunciaStatusUpdate(data).then(dados => {
            resolve([])
        }).catch(error => {
            reject(error);
        })

    });

export const ouvidoriaOuvidoriaCloseServiceAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log('caiu aqi', dados)
        let data = {
            protocolo: dados
        }

        Api.postOuvidoriaCloseService(data).then(dados => {
            resolve([])
        }).catch(error => {
            reject(error);
        })

    });



export const ouvidoriaOuvidoriaSaveAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {

        Api.postOuvidoriaSave(dados).then(dados => {
            resolve(dados)
        }).catch(error => {
            reject(error);
        })

    });