import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { loginRedirectAction } from '../../actions/LoginActions';
import { EspacoTrackExcluirAction, EspacoTrackListAction } from '../../actions/EspacoTrackActions';
import { EspacoTrackVendedoresAction } from '../../actions/EspacoTrackActions';
import { EspacoTrackParceiroAction } from '../../actions/EspacoTrackActions';
import { EspacoTrackShowOcult } from '../../actions/EspacoTrackActions';
import { removerEntregaLocal } from '../../actions/EspacoTrackActions';
import { Button, Checkbox, Dimmer, Dropdown, Icon, Input, Label, Loader, Menu, MenuItem, Popup, Table, TableBody, TableCell, TableFooter, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
var moment = require('moment');

import styled, { css, keyframes } from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;

    td, th {
      border-left: none !important;
      border-right: none !important;
    }
  }    
`;

const StyledTableHeader = styled(Table.Header)`
  &&& th {
    padding: 3px 0px;
    margin: 0px 0px 0px 0px;
    background-color: #155484;
    border-left: none !important;
    border-right: none !important;
  }
`;

const StyledTableBody = styled(Table.Body)`
  &&& td {
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    border-left: none !important;
    border-right: none !important;
  }
`;

const StyledTableCell = styled(Table.Cell)`
  &&& td {
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    border-left: none !important;
    border-right: none !important;
  }
`;



const StyledDropdown = styled(Dropdown)`
  &&& {
    div{
        font-size:10px !important;
        width: 100% !important;
    }
    span{
        font-size:10px !important;
        width:80px !important;
    }
}`

const pulseGreen = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 255, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
  }
`;

const pulseRed = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(220, 53, 69, 0.2);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
  }
`;

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  ${({ pulse }) =>
        pulse.includes('A CAMINHO') && !pulse.includes('OUTRO') ?
            css`
      animation: ${pulseGreen} 1.5s infinite;
      position: relative;
    ` : pulse.includes('OCORRENCIA') ? css`
      animation: ${pulseRed} 1.5s infinite;
      position: relative;
    ` : null}
  background-color: ${({ pulse }) => (pulse.includes('A CAMINHO') && !pulse.includes('OUTRO') ? '#daf8e5' : pulse.includes('OCORRENCIA') ? '#f8d7da' : 'transparent')};
  border-radius: ${({ pulse }) => (pulse ? '3px' : '0')};
`;

const StyledPopup = styled(Popup)`
  &&&::before {
    background-color: #e0fdd5 !important; /* Substitua pela cor desejada */
  }
`;
const StyledPopupCinza = styled(Popup)`
  &&&::before {
    background-color: #f7f5ff !important; /* Substitua pela cor desejada */
  }
`;

function Entregas(props) {

    const { EspacoTrackListAction, EspacoTrackVendedoresAction, EspacoTrackParceiroAction, EspacoTrackExcluirAction, EspacoTrackShowOcult, removerEntregaLocal } = props;
    const { nomeparc, userid, entregas, vendedores, parceiro, isloading, isloadingparceiro, showOcult } = props;
    const [vendedorDropdown, setVendedorDropdown] = useState(null);
    const [filtro, setFiltro] = useState('');
    const [nunota, setNunota] = useState('');
    const [confirmacaoEntregaOk, setConfirmacaoEntregaOk] = useState(false);
    const [linhaSelecionada, setLinhaSelecionada] = useState(null);
    const [isLoadingDados, setIsLoadingDados] = useState(false);


    useEffect(() => {
        EspacoTrackVendedoresAction(userid);
    }, []);

    useEffect(() => {
        // Define o vendedorDropdown quando os vendedores estiverem disponíveis
        if (vendedores.length > 0) {
            setVendedorDropdown(vendedores[0]?.codvend);
            console.log('vendedor array ->', vendedores[0]?.codvend);
        }
    }, [vendedores]); // Executa apenas quando o array "vendedores" for alterado

    useEffect(() => {
        if (vendedorDropdown) {
            EspacoTrackListAction(vendedorDropdown, showOcult);
            console.log('vendedor dropdown ->', vendedorDropdown);
        }
    }, [vendedorDropdown, showOcult]);

    const entregasFiltradas = entregas
        // .sort((a, b) => new Date(b.dtfatur) - new Date(a.dtfatur))
        .filter((item) => {

            const textoBusca = filtro.toLowerCase();


            // Verifica se o texto digitado está presente em algum dos campos
            return (
                item.ordemcarga?.toString().toLowerCase().includes(textoBusca) ||
                item.nunota?.toString().toLowerCase().includes(textoBusca) ||
                item.numnota?.toString().toLowerCase().includes(textoBusca) ||
                moment(item.dtfatur).format('DD/MM/YYYY').toLowerCase().includes(textoBusca) ||
                item.parceiro?.toLowerCase().includes(textoBusca) ||
                item.codparc?.toString().toLowerCase().includes(textoBusca) ||
                item.detalhes_pedido?.toLowerCase().includes(textoBusca)
            );
        });

    const handleSearchNunota = (value) => {
        setNunota(value);
        EspacoTrackParceiroAction(value, userid);
    };

    const handleEntregaOk = async (nunota, oc, userid) => {
        setLinhaSelecionada(null);

        console.log("nunota ->", nunota);
        console.log("ordemcarga ->", oc);
        console.log("userid ->", userid);

        try {
            // Remover do Redux primeiro para atualização instantânea da UI
            removerEntregaLocal(nunota);

            // Exibir toast imediatamente
            toast.success(
                <div>
                    <p style={{ margin: 0 }}>Entrega removida com sucesso!</p>
                    <small style={{ margin: 0 }}>Clique aqui para fechar.</small>
                </div>,
                {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    style: {
                        background: "#fff",
                        color: "#000",
                    },
                }
            );

            // Executar a remoção no banco de dados em background
            EspacoTrackExcluirAction(nunota, oc, userid).catch(() => {
                // Caso haja erro na exclusão do banco, opcionalmente você pode restaurar a entrega no Redux
                toast.error(
                    <div>
                        <p style={{ margin: 0 }}>Erro ao remover entrega no banco!</p>
                        <small style={{ margin: 0 }}>A entrega pode reaparecer na lista.</small>
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        style: {
                            background: "#fff",
                            color: "#000",
                        },
                    }
                );
            });
        } catch (error) {
            toast.error(
                <div>
                    <p style={{ margin: 0 }}>Erro ao remover entrega!</p>
                    <small style={{ margin: 0 }}>Tente novamente.</small>
                </div>,
                {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    style: {
                        background: "#fff",
                        color: "#000",
                    },
                }
            );
        }
    };


    const openWhatsApp = (telefone) => {
        console.log('telefone ->', telefone)
        const message = "Olá, sou vendedor da Esplane/Você Constroi, estou te enviando informações sobre o rastreio da sua entrega:"; // Mensagem opcional
        const encodedMessage = encodeURIComponent(message);
        const url = `https://wa.me/${telefone}?text=${encodedMessage}`;

        window.open(url, "_blank"); // Abre o WhatsApp Web em uma nova aba
    };

    const handleRefresh = () => {
        //alert(showOcult);
        EspacoTrackListAction(vendedorDropdown, showOcult);
    }

    const handleShowOcult = () => {
        EspacoTrackShowOcult(showOcult === 0 ? 1 : 0)
        // alert(showOcult);
    }

    return (
        <>
            <ToastContainer className='foo' />
            {isloading === true || setIsLoadingDados === true ? (
                <div style={{ marginTop: '200px' }}>
                    <Dimmer active inverted>
                        <Loader inverted>Carregando...</Loader>
                    </Dimmer>
                </div>
            ) : (
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Icon style={{ fontSize: '20px', marginLeft: '20px', cursor: 'pointer', color: '#175483' }} onClick={handleRefresh} size='large' name='refresh' />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '90px' }}>
                            <Checkbox toggle onChange={handleShowOcult} checked={showOcult === 1} />
                            <p style={{ all: 'unset', fontSize: '10px', color: '#175483' }}>Exibir notas ocultas</p>
                        </div>
                        <div style={{ width: '250px' }}>
                            <StyledDropdown
                                options={vendedores.map((item) => ({
                                    key: item.codvend, // Identificador único
                                    text: item.apelido, // Texto visível
                                    value: item.codvend, // Valor associado
                                }))}
                                selection
                                placeholder={vendedorDropdown}
                                name='vendedores'
                                fluid
                                scrolling
                                value={vendedorDropdown}
                                onChange={(e, { value }) => {
                                    setVendedorDropdown(value)
                                }}
                                defaultValue={vendedorDropdown}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ fontSize: '12px' }}> Filtro:</div>
                            <Input style={{ height: '40px', marginLeft: '10px', width: '250px' }} placeholder='Parceiro, Status ou Classificação' icon='search' value={filtro} onChange={(e) => setFiltro(e.target.value)} />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginTop: '10px', fontSize: '12px' }}>URL de rastreio a partir de uma NU:</div>
                            <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input style={{ width: '170px', marginRight: '20px' }} placeholder='Digite a NU' action={{
                                        icon: 'caret square right outline',
                                        style: { backgroundColor: '#155484', color: '#ffffff' },
                                    }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSearchNunota(e.target.value);
                                            }
                                        }}
                                        onBlur={(e) => handleSearchNunota(e.target.value)} loading={isloadingparceiro} />
                                    <div style={{ marginLeft: '30px', maxWidth: '180px' }}> <a style={{ color: '#155484', textDecoration: 'underline' }} target='_blank' href={`https://rastreamento.esplane.com.br/${parceiro[0]?.codger === 230 ? 'voceconstroi' : 'esplane'}/${parceiro[0]?.codparc}000${nunota}`}>{parceiro[0] && parceiro[0]?.nomeparc + '(' + parceiro[0]?.codparc + ')'}</a> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <StyledTable celled compact='very' size='small' style={{ marginTop: '15px' }}>
                        <StyledTableHeader>
                            <TableRow>
                                <TableHeaderCell style={{ textAlign: 'center', color: '#FFFFFF', fontSize: '10px' }}>OC</TableHeaderCell>
                                <TableHeaderCell width={1} style={{ textAlign: 'center', color: '#FFFFFF', fontSize: '10px' }}>NU / NF</TableHeaderCell>
                                {/* <TableHeaderCell width={1} style={{ textAlign: 'center', color: '#FFFFFF', fontSize: '10px' }}>NF</TableHeaderCell> */}
                                <TableHeaderCell width={1} style={{ textAlign: 'center', color: '#FFFFFF', fontSize: '10px' }}>Data NF</TableHeaderCell>
                                <TableHeaderCell style={{ textAlign: 'center', color: '#FFFFFF', fontSize: '10px' }}>Parceiro</TableHeaderCell>
                                <TableHeaderCell style={{ textAlign: 'center', color: '#FFFFFF', fontSize: '10px' }}>Situação das entregas</TableHeaderCell>
                                <TableHeaderCell width={0} style={{ textAlign: 'center', color: '#FFFFFF', fontSize: '12px', verticalAlign: 'middle' }}>
                                    <Icon name='trash alternate' />
                                </TableHeaderCell>
                                <TableHeaderCell width={0} style={{ textAlign: 'center', color: '#FFFFFF', fontSize: '12px', verticalAlign: 'middle' }}>
                                    FEC
                                </TableHeaderCell>
                                <TableHeaderCell width={0} style={{ textAlign: 'center', color: '#FFFFFF', fontSize: '10px', verticalAlign: 'middle' }}>
                                    <Icon size='large' name='whatsapp' />
                                </TableHeaderCell>
                            </TableRow>
                        </StyledTableHeader>

                        <StyledTableBody>
                            {entregasFiltradas.map((item, index) => (
                                <TableRow style={{ backgroundColor: index % 2 === 0 ? '#f7f5ff' : '#ffffff', }} kew={index}>
                                    <TableCell style={{ fontSize: '10px', textAlign: 'center' }}>{item.ordemcarga}</TableCell>
                                    <StyledTableCell style={{ fontSize: '8px', textAlign: 'center' }}>{item.nunota} / {item.numnota}</StyledTableCell>
                                    {/* <TableCell style={{ fontSize: '12px', textAlign: 'center' }}>{item.numnota}</TableCell> */}
                                    <TableCell style={{ fontSize: '10px', textAlign: 'center' }}>{moment(item.dtfatur).format('DD/MM/YYYY')} </TableCell>
                                    <TableCell style={{ fontSize: '10px' }}><div style={{ marginLeft: '20px' }}>{item.parceiro}({item.codparc})</div></TableCell>
                                    <TableCell style={{ fontSize: '10px', textAlign: 'center', minWidth: '650px' }}>
                                        <StyledDiv pulse={item.status_pedido}><a status={item.status_pedido} style={{ paddingTop: '10px', color: '#155484', textDecoration: 'underline' }} target='_blank' href={`https://rastreamento.esplane.com.br/${item.codger === 230 ? 'voceconstroi' : 'esplane'}/${item.codparc}000${item.nunota}`}> {item.detalhes_pedido}<p style={{ all: 'unset', color: 'red' }}> {item.evento !== null && (`(${item.evento})`)}</p></a>
                                        </StyledDiv>
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', fontSize: '9px', verticalAlign: 'middle' }}>

                                        {linhaSelecionada === index && confirmacaoEntregaOk === true ? (
                                            <>
                                                <Button
                                                    style={{ fontSize: '8px' }}
                                                    basic
                                                    icon='check'
                                                    color='green'
                                                    size='mini'
                                                    onClick={() => handleEntregaOk(item.nunota, item.ordemcarga, userid)}
                                                />
                                                <Button
                                                    style={{ fontSize: '8px' }}
                                                    basic
                                                    icon='cancel'
                                                    color='red'
                                                    size='mini'
                                                    onClick={() => setLinhaSelecionada(null)}
                                                />
                                            </>
                                        ) : (
                                            <div style={{ width: '50px' }}>
                                                <Icon size='large' style={{ cursor: 'pointer' }} disabled={item.is_exclusao === 1 ? true : false} onClick={() => {
                                                    setLinhaSelecionada(index); // Define a linha selecionada
                                                    setConfirmacaoEntregaOk(true); // Ativa a confirmação
                                                }} name='trash alternate' color={item.is_exclusao === 0 ? 'red' : 'grey'} />
                                            </div>
                                        )}


                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', width: '20px' }}>
                                        <div style={{ width: '50px' }}>
                                            <StyledPopupCinza
                                                on="hover"
                                                hoverable
                                                position='top right'
                                                style={{ backgroundColor: '#f7f5ff', borderRadius: '10px', border: 'none' }}
                                                content={
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        maxHeight: '200px', /* Altura máxima do popup */
                                                        overflowY: 'auto', /* Habilita scroll quando necessário */
                                                        padding: '10px', /* Espaço interno */
                                                        scrollbarWidth: 'thin', /* Para Firefox */
                                                        scrollbarColor: '#888 #f7f5ff' /* Cor do scroll e do fundo no Firefox */
                                                    }}>


                                                        <b style={{ alignSelf: 'center' }}>Formulário Entrega Certa</b>
                                                        <br />

                                                        {/* Conteúdo com rolagem */}

                                                        <p
                                                            style={{
                                                                color: item.fec_detalhes === null ? 'red' : '#061300',
                                                                fontStyle: item.fec_detalhes === null ? 'italic' : 'normal',
                                                                fontSize: '12px',
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: item.fec_detalhes === null ? 'Cliente não respondeu o formulário.' : item.fec_detalhes
                                                            }}
                                                        ></p>
                                                    </div>
                                                }
                                                trigger={
                                                    <Icon disabled={item.fec_detalhes === null ? true : false} style={{ color: item.fec_detalhes === null ? '#ccc' : 'red' }} size='large' name='comment alternate outline' />
                                                }
                                            />



                                        </div>

                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', fontSize: '10px', verticalAlign: 'middle' }}>

                                        <StyledPopup on="hover"
                                            hoverable position='top right' style={{ backgroundColor: '#e0fdd5', borderColor: '#e0fdd5', borderRadius: '10px', border: 'none' }}
                                            content={
                                                <div style={{ display: 'flex' }}>
                                                    <div>
                                                        <p style={{ all: 'unset', fontWeight: 'bold', color: '#008068' }}>{item.parceiro} </p>
                                                        <p
                                                            style={{
                                                                color: item.whatsapp_cliente === null ? 'red' : '#061300',
                                                                fontStyle: item.whatsapp_cliente === null ? 'italic' : 'normal',
                                                                fontSize: '12px',
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    item.whatsapp_cliente === null
                                                                        ? 'Cliente não possui whatsapp cadastrado.'
                                                                        : item.whatsapp_detalhes,
                                                            }}
                                                        ></p>

                                                    </div>
                                                    {/* <p style={{ all: 'unset', fontSize: '10px', display: 'flex', alignItems: 'flex-end', color: '#90ac86' }}>
                                                        16:50
                                                        <img src='/img/check-whatsapp.png' style={{ width: '15px', height: '15px', marginLeft: '5px', marginBottom: '3px' }} />
                                                    </p> */}
                                                </div>
                                            }
                                            trigger={
                                                // <Button disabled={item.whatsapp_cliente === null ? true : false} onClick={() => openWhatsApp(item.whatsapp_cliente)} size='mini' style={{ fontSize: '9px', backgroundColor: item.whatsapp_cliente === null ? '#d8d7da' : "#60f677", width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', marginLeft: '25px', marginTop: '5px', marginBottom: '5px' }}>
                                                <Icon disabled={item.whatsapp_cliente === null ? true : false} onClick={() => openWhatsApp(item.whatsapp_cliente)} style={{ color: item.whatsapp_cliente === null ? '#d8d7da' : '#60f677', fontWeight: 'bold', cursor: 'pointer' }} size='large' name='whatsapp' />

                                                // </Button>
                                            } />
                                        {/* <Icon disabled={item.whatsapp_cliente === null ? true : false} onClick={() => openWhatsApp(item.whatsapp_cliente)} style={{ color: item.whatsapp_cliente === null ? '#d8d7da' : '#60f677', fontWeight: 'bold', cursor: 'pointer' }} size='large' name='whatsapp' /> */}
                                    </TableCell>
                                </TableRow>
                            ))}

                        </StyledTableBody>
                    </StyledTable>
                </>
            )}
        </>
    );

}

const mapStateToProps = (state) => ({
    nomeparc: state.LoginReducer.nomeparc,
    userid: state.LoginReducer.userid,
    entregas: state.EspacoTrackReducer.entregas,
    vendedores: state.EspacoTrackReducer.vendedores,
    isloading: state.EspacoTrackReducer.isloading,
    parceiro: state.EspacoTrackReducer.parceiro,
    isloadingparceiro: state.EspacoTrackReducer.isloadingParceiro,
    showOcult: state.EspacoTrackReducer.showOcult
})


export default withRouter(connect(mapStateToProps, {
    EspacoTrackListAction,
    EspacoTrackVendedoresAction,
    EspacoTrackParceiroAction,
    EspacoTrackExcluirAction,
    EspacoTrackShowOcult,
    removerEntregaLocal
}
)(Entregas));